import { Checkbox as ChakraCheckbox, CheckboxProps, Icon } from '@chakra-ui/react';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import React from 'react';

interface Props extends CheckboxProps {
  label?: string | React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  name?: string;
}

const Checkbox: React.FC<Props> = ({ label = '', disabled, checked, ...props }) => {
  return (
    <ChakraCheckbox
      p={{ base: '5px 12px', md: '10px 24px' }}
      rounded="md"
      _hover={{ bg: 'greenTradewind' }}
      isDisabled={disabled}
      isChecked={checked}
      icon={checked ? <Icon as={FaCheck} /> : undefined}
      variant="labelWithBg"
      {...props}
    >
      {label}
    </ChakraCheckbox>
  );
};

export default Checkbox;
