import { StyleConfig } from '@chakra-ui/react';

const Switch: StyleConfig = {
  variants: {
    skinny: {
      track: {
        bg: '#BB4572',
        padding: '2px',
        height: '8px',
        overflow: 'show',
        px: 0,
        width: '32px',
      },
      thumb: {
        border: '1px solid',
        borderColor: '#BB4572',
        ml: '0px',
        mt: '-5px',
        height: '18px',
        width: '18px',
        _checked: {
          ml: '3px',
        },
      },
    },
  },
};

export default Switch;
