import { ApolloError } from '@apollo/client';
import * as Yup from 'yup';

import { carDetailLicensePlate } from '../../gql/carGql';
import { apolloClient } from '../../providers/AuthorizedApolloProvider';

const INVALID_LICENSE_PLATE_MSG = 'Invalid license plate or state';

interface ValidateLicenseNumberOptions {
  licenseNumber?: string;
  state?: string;
  context?: Yup.TestContext;
  validateWithApi?: boolean;
}

const validateLicenseNumber = async ({
  licenseNumber,
  state,
  context,
  validateWithApi,
}: ValidateLicenseNumberOptions) => {
  if (!licenseNumber) {
    return false;
  }

  const strippedLicensePlate = licenseNumber.replace(/[\s-]*/g, '');
  const regEx = /^[a-z0-9\s]{4,8}$/;
  if (!strippedLicensePlate.toLowerCase().match(regEx)) {
    return false;
  }
  if (!validateWithApi) return true;

  // If validateWithApi is true, make sure to not make duplicate API calls
  try {
    const { data } = await apolloClient.query({
      query: carDetailLicensePlate,
      variables: {
        license_plate_number: strippedLicensePlate.toUpperCase(),
        license_plate_state: state,
      },
    });

    if (data.carDetailLicensePlate) return true;
    return false;
  } catch (e) {
    if (!context) {
      return false;
    }

    if (e instanceof ApolloError && e.message.includes('405')) {
      return context.createError({
        message: 'Looks like our license plate tool isn’t working. Try VIN instead.',
      });
    }

    return context.createError({
      message: INVALID_LICENSE_PLATE_MSG,
    });
  }
};

export { INVALID_LICENSE_PLATE_MSG };
export default validateLicenseNumber;
