import { Box, BoxProps, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';

import { BlueCar, DocumentSignatures, PhoneGuy, SleepyCouch, Tags } from '../../../assets/Images';
import useHomePageVariations from '../../../hooks/useHomePageVariations';

const Steps = ({ ...props }: BoxProps) => {
  const homePageVariation = useHomePageVariations();

  return (
    <Box mb={20} maxW="1400px" mx="auto" px={{ base: '20px', md: '50px' }} {...props}>
      <Heading fontWeight="semibold" fontSize={{ base: 25, md: 35 }} textAlign="center">
        {homePageVariation.steps.title}
      </Heading>
      <Flex flexWrap="wrap">
        <Flex
          p={4}
          w={{ base: '50%', md: 'calc(25% - 2px)' }}
          borderBottom={{ base: '1px solid' }}
          borderBottomColor={{ base: 'grayBackground', md: 'transparent' }}
          borderRight={{ base: '1px solid' }}
          borderRightColor={{ base: 'grayBackground', md: 'transparent' }}
          flexDir="column"
        >
          <Text mb="10px" fontWeight="bold">
            Step 1: Tell us about your car.
          </Text>
          <Grid>
            <Image src={BlueCar} gridRow="1/2" gridColumn="1/2" alt="Tell us about your car." />
            <Image
              src={PhoneGuy}
              w="40%"
              gridRow="1/2"
              gridColumn="1/2"
              justifySelf="end"
              mr="20px"
              alt="Tell us about your car"
            />
          </Grid>
          <Text mt="auto" fontSize="12px">
            {homePageVariation.steps.descriptions[0]}
          </Text>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          border="1px solid"
          borderColor="headlightLadyBlueLight"
          w="1px"
          h="160px"
          my="20px"
        />
        <Flex
          p={4}
          w={{ base: '50%', md: 'calc(25% - 2px)' }}
          borderBottom={{ base: '1px solid' }}
          borderBottomColor={{ base: 'grayBackground', md: 'transparent' }}
          flexDir="column"
        >
          <Text mb="10px" fontWeight="bold">
            Step 2: View your loan & coverage options.
          </Text>
          <Image src={Tags} alt="View your loan options." />
          <Text mt="auto" fontSize="12px">
            {homePageVariation.steps.descriptions[1]}
          </Text>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          border="1px solid"
          borderColor="headlightLadyBlueLight"
          w="1px"
          h="160px"
          my="20px"
        />
        <Flex
          p={4}
          w={{ base: '50%', md: 'calc(25% - 2px)' }}
          borderRight={{ base: '1px solid' }}
          borderRightColor={{ base: 'grayBackground', md: 'transparent' }}
          flexDir="column"
        >
          <Text mb="10px" fontWeight="bold">
            Step 3: Sign your buyout documents.
          </Text>
          <Image src={DocumentSignatures} w="80%" alt="Sign your buyout documents." />
          <Text mt="auto" fontSize="12px">
            {homePageVariation.steps.descriptions[2]}
          </Text>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          border="1px solid"
          borderColor="headlightLadyBlueLight"
          w="1px"
          h="160px"
          my="20px"
        />
        <Flex p={4} w={{ base: '50%', md: '24.9%' }} flexDir="column">
          <Text mb="10px" fontWeight="bold">
            Step 4: Relax - we'll take it from there.
          </Text>
          <Image src={SleepyCouch} alt="Relax - we'll take it from there." />
          <Text mt="auto" fontSize="12px">
            {homePageVariation.steps.descriptions[3]}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Steps;
