import { gql } from '@apollo/client/core';

export const paymentEstimate = gql`
  query paymentEstimate($data: paymentEstimateInput) {
    paymentEstimate(data: $data) {
      paymentEstimateLow
      paymentEstimateHigh
      estimatedTaxesAndFees
      totalEstimatedLoan
      estimatedAnnualPercentageRate
      feeDetails {
        baseTaxAmount
        warrantyTaxAmount
        docFeeTaxAmount
        docFee
        titleFee
        registrationFee
      }
    }
  }
`;

export const sendPaymentEstimateEmailAndText = gql`
  mutation sendPaymentEstimateEmailAndText(
    $data: paymentEstimateInput!
    $email: String!
    $phoneNumber: String!
  ) {
    sendPaymentEstimateEmailAndText(data: $data, email: $email, phoneNumber: $phoneNumber)
  }
`;
