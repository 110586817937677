export const ONE_DAY_MS = 86_400_000;

export const getUTCDate = (date: string | Date) => {
  const zonedDate = typeof date === 'string' ? new Date(date) : date;

  return new Date(zonedDate.getUTCFullYear(), zonedDate.getUTCMonth(), zonedDate.getUTCDate());
};

export const getDatePart = (date: string | Date) => {
  const zonedDate = typeof date === 'string' ? new Date(date) : date;

  return new Date(zonedDate.getFullYear(), zonedDate.getMonth(), zonedDate.getDate());
};

export const convertTimeZone = (date: string | Date, timeZone: string) => {
  const zonedDate = typeof date === 'string' ? new Date(date) : date;

  return new Date(zonedDate.toLocaleString('en-US', { timeZone }));
};

const LeaseEndTimeZone = 'America/Denver';

export const convertToLeaseEndTimeZone = (date: string | Date) =>
  convertTimeZone(date, LeaseEndTimeZone);

export const formatDateISO = (date: Date) => date.toISOString().split('T')[0];

export const dateOrdinal = (number: number) => {
  if (number > 3 && number < 21) return 'th';
  switch (number % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const checkLastDayOfMonth = () => {
  const date = new Date();
  const dayOfMonth = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  const isLeapYear = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

  const lastDaysOfMonths = {
    0: 31,
    1: isLeapYear ? 29 : 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31,
  };

  return dayOfMonth === lastDaysOfMonths[month as keyof typeof lastDaysOfMonths];
};

export const getDay = (date: Date) => date.getDate();

export const getMonth = (date: Date, monthFormat?: Intl.DateTimeFormatOptions['month']) =>
  new Intl.DateTimeFormat('en-US', { month: monthFormat ?? 'long' }).format(date);

export const addMonthsToDate = (months: number, date = new Date()) => {
  const newDate = new Date(date.getTime());
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

export const getFutureDate = (days: number) => {
  const todayDate = new Date();

  todayDate.setDate(todayDate.getDate() + days);

  const futureMonth = new Intl.DateTimeFormat('en-US', { month: 'long' })
    .format(todayDate)
    .toLocaleUpperCase();
  const futureDay = todayDate.getDate();

  return {
    futureDay,
    futureMonth,
  };
};
