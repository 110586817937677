import { StyleConfig } from '@chakra-ui/react';

const tooltip: StyleConfig = {
  baseStyle: {
    p: 4,
    borderRadius: 'lg',
    fontSize: '12px',
    color: 'white',
  },
};

export default tooltip;
