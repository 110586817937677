import { Image, Text, VStack } from '@chakra-ui/react';

import { BestMoneyLogo } from '../../../assets/Images';

interface Props {
  alignLeft?: boolean;
}

const BestMoneyPartnershipBadge = ({ alignLeft = false }: Props) => {
  return (
    <VStack
      mt={{ base: '0', md: '15px' }}
      mb={2}
      mr={{ base: '0', md: '25px' }}
      ml={alignLeft ? 0 : { base: '15px', md: '-210px' }}
      align="flex-start"
      spacing={1}
    >
      <Text color="black" fontSize="12px">
        In Partnership With
      </Text>
      <Image
        src={BestMoneyLogo}
        alt="Best Money Logo"
        h={{ base: '21px', md: '30px' }}
        w={{ base: '126px', md: '180px' }}
        mr="auto"
      />
    </VStack>
  );
};

export default BestMoneyPartnershipBadge;
