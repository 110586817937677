import { Divider, Flex, FlexProps, useBreakpointValue } from '@chakra-ui/react';
import { Formik } from 'formik';
import { SetStateAction } from 'jotai';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { LDFlags } from '../../constants/experiments';
import {
  DEFAULT_BUYOUT,
  DEFAULT_DOWN_PAYMENT,
  DEFAULT_INITIAL_SCORE,
  DEFAULT_LOAN_TERM,
  PAYMENT_CALCULATOR_VARIATIONS,
} from '../../constants/paymentCalculator';
import {
  OptEventSourceEnum,
  OptEventTypeEnum,
  PaymentEstimate,
  TtGetFeesSourceType,
  useCreateOptEventMutation,
  usePaymentEstimateLazyQuery,
  useSendPaymentEstimateEmailAndTextMutation,
} from '../../gql/generated/graphql';
import useFlag from '../../hooks/useFlag';
import { getCreditScore } from '../../utils/creditScore';
import { termLengths } from '../../utils/paymentCalculator';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { emailValidationNoAlias } from '../../utils/validation/email';
import { phoneValidation } from '../../utils/validation/phoneNumber';
import { zipValidation } from '../../utils/validation/zipCodes';
import { useReCaptcha } from '../ReCaptcha/useReCaptcha';
import Subtitle from '../Subtitle';
import CalculatorForm from './CalculatorForm';
import CalculatorResults from './CalculatorResults';

export type FormFields = {
  email: string;
  credit_score: number;
  down_payment: number;
  phone_number: string;
  term: number;
  vehicle_payoff: number;
  zip: string;
};

interface Props extends FlexProps {
  setParentShowInputs?: Dispatch<SetStateAction<boolean>>;
}
const MonthlyPaymentCalculator = ({ setParentShowInputs, maxW, ...props }: Props) => {
  const [paymentEstimate, setPaymentEstimate] = useState<PaymentEstimate>();
  const [showInputs, setShowInputs] = useState<boolean>(true);
  const [addOptInRecord] = useCreateOptEventMutation();
  const { generateReCaptchaToken } = useReCaptcha({ type: 'invisible' });
  const { pathname } = useLocation();

  const [getPaymentEstimate] = usePaymentEstimateLazyQuery();
  const [sendPaymentEstimateCommunications] = useSendPaymentEstimateEmailAndTextMutation();

  const isMobile = useBreakpointValue({ base: true, md: false });
  const optOutFlag = useFlag(LDFlags.LEASE_END_OPT_OUT);
  const paymentCalculatorVariation = useFlag(LDFlags.PAYMENT_CALCULATOR_VARIATION);
  const results = useRef<HTMLDivElement>(null);

  const initialValues: FormFields = {
    email: '',
    credit_score: DEFAULT_INITIAL_SCORE,
    down_payment: DEFAULT_DOWN_PAYMENT,
    phone_number: '',
    term: DEFAULT_LOAN_TERM,
    vehicle_payoff: DEFAULT_BUYOUT,
    zip: '',
  };

  const validationSchema = Yup.object({
    credit_score: Yup.number().nullable().required('This value is required'),
    down_payment: Yup.number()
      .nullable()
      .max(Yup.ref('vehicle_payoff'), 'Must be less than payoff'),
    email:
      optOutFlag && paymentCalculatorVariation !== PAYMENT_CALCULATOR_VARIATIONS.zipPhone
        ? emailValidationNoAlias
        : Yup.string(),
    phone_number:
      optOutFlag && paymentCalculatorVariation !== PAYMENT_CALCULATOR_VARIATIONS.zipEmail
        ? phoneValidation
        : Yup.string(),
    term: Yup.number().nullable().required('').min(termLengths[0].value, 'This value is required'),
    vehicle_payoff: Yup.number().nullable().required('This value is required'),
    zip: zipValidation,
  });

  const getPaymentEstimateParams = (values: FormFields) => ({
    creditScore: getCreditScore(values.credit_score),
    term: values.term,
    moneyDown: values.down_payment,
    payoff: values.vehicle_payoff,
    zipCode: values.zip,
    ttGetFeesSource: TtGetFeesSourceType.MktPaymentCalculator,
  });

  const onSubmit = async (values: FormFields) => {
    const paymentEstimateVars = getPaymentEstimateParams(values);

    if (optOutFlag) {
      const { email, phone_number } = values;
      await sendPaymentEstimateCommunications({
        variables: {
          data: {
            ...paymentEstimateVars,
          },
          email,
          phoneNumber: phone_number,
        },
      });
      setShowInputs(false);
      setParentShowInputs?.(false);

      const token = await generateReCaptchaToken();
      await addOptInRecord({
        variables: {
          token,
          event: {
            email,
            phone_number,
            source: OptEventSourceEnum.PreFlowPaymentCalculator,
            action: OptEventTypeEnum.OptIn,
          },
        },
      });
      return;
    }
    const { data } = await getPaymentEstimate({
      variables: {
        data: {
          ...paymentEstimateVars,
        },
      },
    });

    if (data?.paymentEstimate) {
      setPaymentEstimate(data?.paymentEstimate);
      setShowInputs(false);
      setParentShowInputs?.(false);
    }
  };

  useEffect(() => {
    rudderanalytics.track(RudderEvent.RStackLandingTest, {
      page: pathname,
    });
  });

  return (
    <Flex
      bgColor="grayBackground"
      flexDirection="column"
      borderRadius="10px"
      p={{ base: '10px', lg: '25px' }}
      mx="auto"
      maxW={maxW || { lg: '680px' }}
      w="100%"
      {...props}
    >
      <Subtitle fontSize={24} textAlign="center">
        {showInputs
          ? "Let's crunch some numbers!"
          : `Woohoo! ${
              optOutFlag ? 'We sent you your estimated monthly payment' : 'Here are your numbers'
            }`}
      </Subtitle>
      <Divider w="100%" bg="gray" pt="1px" />
      <Formik<FormFields>
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values) => onSubmit(values)}
      >
        {({ setSubmitting }) => {
          return showInputs ? (
            <CalculatorForm optOutFlag={optOutFlag} />
          ) : (
            <CalculatorResults
              paymentEstimate={paymentEstimate as PaymentEstimate}
              parent={results.current}
              isMobile={isMobile}
              goBack={() => {
                setSubmitting(false);
                setShowInputs(true);
              }}
              optOutFlag={optOutFlag}
            />
          );
        }}
      </Formik>
    </Flex>
  );
};

export default MonthlyPaymentCalculator;
