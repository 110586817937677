import { Box, Flex, Image, ScaleFade, Text, keyframes } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction } from 'react';

import { LeasePeaceBackground, LeasePeaceMeditator } from '../../../assets/Images';
import Nav from '../../../components/Nav/Nav';

interface UploadingProps {
  review: {
    review: string;
    name: string;
    color: string;
    letters: string;
  };
  setFakeUploading: Dispatch<SetStateAction<boolean>>;
  fakeUploading: boolean;
}

const Uploading: FC<UploadingProps> = ({ review, setFakeUploading, fakeUploading }) => {
  const animationKeyframes = keyframes`
    0% { margin-left: 650px; padding: 50px;}
    20% { margin-left: -45px; rotate: -1deg; padding: 0px;}
    40% { margin-left: 45px; rotate: 1deg; padding: 0px;}
    60% { margin-left: -45px; rotate: -1deg; padding: 0px;}
    80% { margin-left: 45px; rotate: -1deg; padding: 0px;}
    100% { margin-left: 650px; padding: 50px;}
  `;

  const animation = `${animationKeyframes} 7s ease-in-out infinite`;

  setTimeout(() => {
    setFakeUploading(false);
  }, 7000);

  return (
    <Box backgroundImage={LeasePeaceBackground} backgroundSize="cover">
      <Nav />
      <Flex flexDir="column" justifyContent="center" textAlign="center" pb="30px">
        <ScaleFade in={fakeUploading}>
          <Text fontSize="72px" fontWeight="800">
            Uploading
          </Text>
          <Text fontSize="32px">We hope you're having a good day so far</Text>
        </ScaleFade>
        <Image
          src={LeasePeaceMeditator}
          alt="Lease Peace Mediator"
          h="600px"
          mt="45px"
          animation={animation}
          zIndex="1"
        />
        <Box textAlign="center" mt="-100px" mb="20px">
          <Text fontWeight="bold" fontSize="20px">
            {review.review}
          </Text>
          <Flex m="0 auto" alignItems="center" justifyContent="center" flexDir="column">
            <Text fontSize="16px" zIndex="2">
              {review.name}
            </Text>
            <Text
              fontSize="18px"
              bgColor={review.color}
              color="white"
              ml="5px"
              p="5px"
              textAlign="center"
              borderRadius="50%"
              zIndex="2"
            >
              {review.letters}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Uploading;
