import { PortableText } from '@portabletext/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LandingPageDrill, LandingPageStateContexts } from '../../../constants/atom';
import { landingPageFetch } from '../Data';
import { LandingPageProps, ptLandingPageComponents } from '../Model';
import { SANITY_LP_URL_PREFIX } from '../Vars';

const SanityLandingPage = () => {
  const [, setLandingPageDrill] = useAtom(LandingPageDrill);
  const [, setLandingPageStateContexts] = useAtom(LandingPageStateContexts);
  const { slug, drill } = useParams<{ slug: string; drill: string }>();
  const [, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<LandingPageProps | null>(null);
  const [pageTitle, setPageTitle] = useState<string | null | undefined>(null);
  const sanitySlug: string = slug.replace(SANITY_LP_URL_PREFIX, '');

  if (drill) {
    setLandingPageDrill(drill);
  }

  const matchDrill = () => {
    setPageTitle(drill && page?.states.includes(drill) ? drill : page?.title);
  };
  const fetch = async () => {
    setLoading(true);
    const data: LandingPageProps | null = await landingPageFetch(sanitySlug);
    setLoading(false);
    setPage(data);
  };
  useEffect(() => {
    fetch();
  }, []);
  useEffect(() => {
    if (page) {
      setLandingPageStateContexts(page?.states ? page.states.join() : '');
      matchDrill();
    }
  }, [page]);
  if (!page) {
    return null;
  }
  return (
    <>
      <h1>{pageTitle}</h1>
      <PortableText value={page?.body} components={ptLandingPageComponents} />
    </>
  );
};

export default SanityLandingPage;
