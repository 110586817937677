import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface RedirectToComProps {
  to: string;
}

const RedirectToCom = ({ to }: RedirectToComProps) => {
  const { search } = useLocation();

  useEffect(() => {
    if (to) {
      window.location.href = to + search;
    }
  }, [to]);

  return null;
};

export default RedirectToCom;
