import { Flex, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';

const CustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
  decreaseYear,
  decreaseMonth,
  date,
  increaseMonth,
  increaseYear,
}) => {
  return (
    <Flex justifyContent="space-between">
      <Icon
        as={BiChevronsLeft}
        w={6}
        h={6}
        color="royalBlue"
        cursor="pointer"
        onClick={decreaseYear}
      />
      <Icon
        as={BiChevronLeft}
        w={6}
        h={6}
        color="royalBlue"
        cursor="pointer"
        onClick={decreaseMonth}
      />
      <Flex>
        <Text mx={1}>{date.toLocaleString('en-US', { month: 'short' })}</Text>
        <Text mx={1}>{date.getFullYear()}</Text>
      </Flex>
      <Icon
        as={BiChevronRight}
        w={6}
        h={6}
        color="royalBlue"
        cursor="pointer"
        onClick={increaseMonth}
      />
      <Icon
        as={BiChevronsRight}
        w={6}
        h={6}
        color="royalBlue"
        cursor="pointer"
        onClick={increaseYear}
      />
    </Flex>
  );
};

export default CustomHeader;
