import { string } from 'yup';

// validate for US phone numbers
const phoneRegExp = /^(?:\+?1-?)?(?:\d{3}|\(\d{3}\))[- ]?\d{3}[- ]?\d{4}$/;

export const phoneValidation = string()
  .required('Please enter a phone number')
  .matches(phoneRegExp, 'Please enter a valid phone number');

export default phoneRegExp;
