import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import { FaPhoneAlt } from '@react-icons/all-files/fa/FaPhoneAlt';
import { IoIosMenu, IoMdLock } from 'react-icons/io';
import { MdPerson } from 'react-icons/md';
import { RiAccountCircleFill } from 'react-icons/ri';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';

import {
  Logo,
  LogoWhite,
  PaymentCalculatorIcon,
  PaymentCalculatorIconWhite,
  SmallLogo,
  SmallLogoWhite,
} from '../../assets/Images';
import { LDFlags } from '../../constants/experiments';
import {
  ABOUT_US,
  ACCOUNT_LOGIN,
  BEST_MONEY,
  BLOG,
  BLOG_SANITY,
  DASHBOARD, // BLOG,
  FAQS,
  HOME,
  HOW_IT_WORKS,
  MONTHLY_PAYMENT_CALCULATOR, // REFI,
  REVIEWS,
} from '../../constants/urls';
import { useFlag } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import useSearchPhoneNumbers from '../../hooks/useSearchPhoneNumbers';
import useWindowSize from '../../hooks/useWindowSize';
import BestMoneyPartnershipBadge from '../ui/GetStarted/BestMoneyPartnershipBadge';
import NavLink from './NavLink';

export const FAQ_BREAKPOINT = 1000;
export const LEARN_BREAKPOINT = 1050;
export const ABOUT_US_BREAKPOINT = 1100;
export const REVIEWS_BREAKPOINT = 1150;

interface NavProps {
  inFlow?: boolean;
  hideMenuItems?: boolean;
  inverse?: boolean;
}

const Nav = ({ inFlow = false, hideMenuItems = false, inverse = false }: NavProps) => {
  const SANITY_BLOG = useFlag(LDFlags.SANITY_BLOG);
  const isDesktop = useBreakpointValue({
    base: false,
    md: true,
  });

  const showSmallerLogo = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  });

  const navColor: string = inverse ? 'white' : 'leaseEndBlue';
  const secondaryNavColor: string = inverse ? 'white' : 'oceanBoatBlue';

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();
  const phoneNumber = useSearchPhoneNumbers();
  const { windowWidth } = useWindowSize();
  const [isSmallerThan355] = useMediaQuery('(max-width: 355px)');

  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;

  const hideMenuLinks = hideMenuItems || inFlow;
  const showAuthLink = isAuthenticated && !inFlow;
  const showLoginBtn = !isAuthenticated && !inFlow;
  const showPartnershipBadge = pathname === BEST_MONEY && !isDesktop;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      p={{ base: '10px', md: '15px 20px' }}
      maxWidth="1920px"
      m="0 auto"
      position="relative"
      zIndex="1"
      background="transparent"
    >
      {isDesktop ? (
        <>
          <Flex
            alignItems="center"
            gap={inFlow ? 'unset' : '30px'}
            justifyContent={inFlow ? 'space-between' : 'unset'}
          >
            {inFlow && showSmallerLogo ? (
              <Link>
                <ReactRouterLink to={HOME}>
                  <Image src={inverse ? SmallLogoWhite : SmallLogo} alt="Lease End Logo" w="40px" />
                </ReactRouterLink>
              </Link>
            ) : (
              <Link minW={hideMenuLinks ? '220px' : 'unset'}>
                <ReactRouterLink to={HOME}>
                  <Image src={inverse ? LogoWhite : Logo} alt="Lease End Logo" w="130px" />
                </ReactRouterLink>
              </Link>
            )}
            {!hideMenuLinks && (
              <>
                <NavLink color={navColor} to={HOW_IT_WORKS}>
                  How it Works
                </NavLink>
                {windowWidth > FAQ_BREAKPOINT && (
                  <NavLink color={navColor} to={FAQS}>
                    FAQs
                  </NavLink>
                )}
                {windowWidth > LEARN_BREAKPOINT && (
                  <>
                    <NavLink
                      color={navColor}
                      to={SANITY_BLOG ? BLOG_SANITY : BLOG}
                      // eslint-disable-next-line react/no-children-prop
                      isExternal={!SANITY_BLOG}
                    >
                      Learn
                    </NavLink>
                  </>
                )}
                {windowWidth > ABOUT_US_BREAKPOINT && (
                  <NavLink color={navColor} to={ABOUT_US}>
                    About Us
                  </NavLink>
                )}
                {windowWidth > REVIEWS_BREAKPOINT && (
                  <NavLink color={navColor} to={REVIEWS}>
                    Reviews
                  </NavLink>
                )}
                {/* {pathname !== '/refi' && refinance_landing_page ? (
                  <Flex alignItems="center" gap="10px" borderLeft="solid 2px #0000002E" pl="24px">
                    <IconContext.Provider
                      value={{
                        size: '26',
                        color: inverse ? 'white' : '#087dc5',
                      }}
                    >
                      <FaMoneyBillTransfer />
                    </IconContext.Provider>
                    <Link color={secondaryNavColor} fontSize="16px" fontWeight="semibold">
                      <ReactRouterLink to={REFI}>Refinance</ReactRouterLink>
                    </Link>
                  </Flex>
                ) : refinance_landing_page ? (
                  <Flex alignItems="center" gap="10px" borderLeft="solid 2px #0000002E" pl="24px">
                    <IconContext.Provider
                      value={{
                        size: '26',
                        color: inverse ? 'white' : '#087dc5',
                      }}
                    >
                      <PiBankBold />
                    </IconContext.Provider>
                    <Link color="oceanBoatBlue" fontSize="16px" fontWeight="semibold">
                      <ReactRouterLink to={HOME}>Buyout Lease</ReactRouterLink>
                    </Link>
                  </Flex>
                ) : null} */}
                {pathname !== '/monthly-payment-calculator' && (
                  <Flex alignItems="center" gap="10px">
                    <Image
                      w={14}
                      src={inverse ? PaymentCalculatorIconWhite : PaymentCalculatorIcon}
                      alt="Payment Calculator Icon"
                      borderLeft="solid 2px #0000002E"
                      pl="24px"
                    />
                    <Link color={secondaryNavColor} fontSize="16px" fontWeight="semibold">
                      <ReactRouterLink to={MONTHLY_PAYMENT_CALCULATOR}>Calculator</ReactRouterLink>
                    </Link>
                  </Flex>
                )}
              </>
            )}
          </Flex>
          <Flex alignItems="center" gap="40px">
            {!hideMenuLinks && (
              <>
                {showAuthLink && (
                  <Link href={DASHBOARD}>
                    <Button
                      aria-label="Profile"
                      p={0}
                      variant="ghost"
                      _active={{ outline: 'none', bg: 'transparent' }}
                      _focus={{ outline: 'none', bg: 'transparent' }}
                      _hover={{ bg: 'transparent' }}
                      leftIcon={<Icon as={MdPerson} boxSize="26px" />}
                      mr={4}
                      color="oceanBoatBlue"
                    >
                      Dashboard
                    </Button>
                  </Link>
                )}
                {showLoginBtn && (
                  <Link href={ACCOUNT_LOGIN}>
                    <Button
                      leftIcon={<IoMdLock size="20px" color="white" />}
                      fontSize="16px"
                      backgroundColor="oceanBoatBlue"
                      color="white"
                      padding="20px 17.5px"
                      _hover={{
                        boxShadow: 'md',
                        backgroundColor: 'royalBlueLight',
                      }}
                    >
                      Log in
                    </Button>
                  </Link>
                )}
              </>
            )}
            {!showLoginBtn && !showAuthLink && (
              <>
                <Box display={{ base: 'none', lg: 'block' }} mr="10px">
                  <Box mb="-5px" mt="5px">
                    Give Us a Call:
                  </Box>
                  <Link
                    fontSize="2rem"
                    fontWeight="bold"
                    _hover={{ textDecor: 'none' }}
                    href={`tel:+1-${phoneNumber}`}
                    aria-label="Phone number"
                    color="leaseEndBlue"
                  >
                    {phoneNumber}
                  </Link>
                </Box>
                <Box display={{ base: 'block', lg: 'none' }}>
                  <Link fontSize="2rem" href={`tel:+1-${phoneNumber}`} aria-label="Phone Number">
                    <FaPhoneAlt />
                  </Link>
                </Box>
              </>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Flex direction={isSmallerThan355 ? 'column' : 'row'}>
            <Link href={HOME} mx={hideMenuLinks ? 'auto' : undefined} mb={1}>
              <Image src={inverse ? LogoWhite : Logo} alt="Lease End Logo" w="130px" />
            </Link>
            {showPartnershipBadge && <BestMoneyPartnershipBadge alignLeft={isSmallerThan355} />}
          </Flex>
          {!hideMenuLinks && (
            <>
              <Flex pr="10px">
                <Icon
                  as={IoIosMenu}
                  boxSize="40px"
                  onClick={onOpen}
                  color={inverse ? 'white' : 'oceanBoatBlue'}
                />
              </Flex>
              <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton color="white" />
                  <DrawerBody bg="matisseBlue" overflow="hidden">
                    {showLoginBtn && (
                      <Flex alignItems="center" gap="5px">
                        <Icon as={RiAccountCircleFill} boxSize="30px" color="white" />
                        <Link
                          href={ACCOUNT_LOGIN}
                          mt="3px"
                          color="white"
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Login
                        </Link>
                      </Flex>
                    )}
                    {showAuthLink && (
                      <Link href={DASHBOARD}>
                        <Button
                          aria-label="Profile"
                          p={0}
                          variant="ghost"
                          leftIcon={<Icon as={MdPerson} boxSize="26px" />}
                          mr={4}
                          color="white"
                        >
                          Dashboard
                        </Button>
                      </Link>
                    )}
                    <Divider m="10px 0" />
                    <Flex flexDir="column" gap="15px" mt="10px">
                      <NavLink
                        color="white"
                        to={HOME}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Home
                      </NavLink>
                      {/* {refinance_landing_page && (
                        <NavLink
                          color="white"
                          to={REFI}
                          textTransform="uppercase"
                          _hover={{ color: 'white', textDecor: 'underline' }}
                        >
                          Refinance
                        </NavLink>
                      )} */}
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={HOW_IT_WORKS}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        How it Works
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={FAQS}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        FAQs
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        isExternal={!SANITY_BLOG}
                        to={SANITY_BLOG ? BLOG_SANITY : BLOG}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Learn
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={ABOUT_US}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        About Us
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={REVIEWS}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Reviews
                      </NavLink>
                      <NavLink
                        onClick={onClose}
                        color="white"
                        to={MONTHLY_PAYMENT_CALCULATOR}
                        textTransform="uppercase"
                        _hover={{ color: 'white', textDecor: 'underline' }}
                      >
                        Payment Calculator
                      </NavLink>
                    </Flex>
                    <Divider m="10px 0" />
                    <Text color="white" fontSize="20px">
                      Give us a call
                    </Text>
                    <Link
                      href={`tel:${phoneNumber}`}
                      color="white"
                      fontSize="20px"
                      fontWeight="bold"
                      _hover={{ color: 'white', textDecor: 'underline' }}
                    >
                      {phoneNumber}
                    </Link>
                  </DrawerBody>
                </DrawerContent>
              </Drawer>
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default Nav;
