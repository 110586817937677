import { Flex, Heading, Link, Text } from '@chakra-ui/react';

import { useSearchPhoneNumbers } from '../../../hooks';

const ReadyToBuyYourLeasedCar = () => {
  const phoneNumber = useSearchPhoneNumbers();

  return (
    <Flex
      flexDir="column"
      gap={8}
      my={{ base: 8, md: 10 }}
      textAlign="center"
      maxW="container.lg"
      mx="auto"
      fontSize="16px"
      px={4}
    >
      <Heading as="h2" fontWeight="semibold" fontSize={{ base: 25, md: 35 }}>
        Ready to buy your leased car?
      </Heading>
      <Text>
        If you're ready to take the next step and buy your leased car, we're here to help. Our team
        of experts can guide you through the process, answer any questions you may have, and help
        you make an informed decision.
      </Text>
      <Text>
        Contact us today{' '}
        <Link href={`tel:+1-${phoneNumber}`} isExternal>
          {phoneNumber}
        </Link>{' '}
        to learn more about how to buy your leased car and start your journey to car ownership.
      </Text>
    </Flex>
  );
};

export default ReadyToBuyYourLeasedCar;
