/* eslint-disable react/no-array-index-key */

/* eslint-disable prettier/prettier */
import { Button, Container, Flex, Image, Link, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LearnLogo } from '../../assets/Images';
import Title from '../../components/Title';
import { BLOG } from '../../constants/urls';
import Loading from '../Loading';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { blogMenuFetch, featuredPostFetch } from '../Sanity/Data';
import { BlogPostProps, CategoryProps } from '../Sanity/Model';

const Blog = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [nav, setNav] = useState<CategoryProps[] | null>(null);
  const [featured, setFeatured] = useState<BlogPostProps[] | null>(null);

  const fetchNav = async () => {
    const data: CategoryProps[] | null = await blogMenuFetch();
    setNav(data);
  };

  const fetch = async () => {
    setLoading(true);
    const data: BlogPostProps[] | null = await featuredPostFetch();
    setLoading(false);
    setFeatured(data);
  };

  const jump = (slug: string) => {
    history.push(`${BLOG}/${slug}`);
  };

  useEffect(() => {
    fetch();
    fetchNav();
  }, []);

  return (
    <>
      <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" p={0} mt={8}>
        <Flex flexDir="row" justifyContent="flex-start" alignItems="center" w="100%" gap={4}>
          <Image src={LearnLogo} w={260} />
          <Flex bg="oceanBoatBlue" h="56px" flex={1} borderRadius={2} justifyContent="space-around">
            {nav &&
              nav.map((n, x: number) => {
                return (
                  <Link
                    key={`le-nav-${x}`}
                    fontSize={16}
                    fontWeight={600}
                    color="white"
                    lineHeight="52px"
                  >
                    {n.title}
                  </Link>
                );
              })}
          </Flex>
        </Flex>
        {loading ? (
          <Loading />
        ) : featured ? (
          <>
            <Flex w="100%" justifyContent="flex-start" alignItems="flex-start" gap={16} my={10}>
              <Flex w={featured.length === 1 ? '100%' : '66.67%'} flexDir="column">
                <Text textAlign="left" color="oceanBoatBlue" fontWeight={700} fontSize={18} mb={3}>
                  FEATURED ARTICLE
                </Text>
                {featured[0].mainImage && (
                  <BlogImageComponent value={featured[0].mainImage} isInline={false} />
                )}
                <Title
                  fontWeight={700}
                  textAlign="left"
                  m={0}
                  my={5}
                  fontSize={{
                    base: '26pt',
                  }}
                >
                  {featured[0].title}
                </Title>
                <Text textAlign="left" fontSize="12pt" color="#787878" fontWeight={400}>
                  {featured[0].authorName}
                </Text>
                {featured[0].snippet && (
                  <Text textAlign="left" fontSize="14pt" fontWeight={400} color="black">
                    {featured[0].snippet}
                  </Text>
                )}
                <Flex mt={5}>
                  <Button onClick={() => jump(featured[0].slug.current)}>READ MORE</Button>
                </Flex>
              </Flex>
              {featured.length > 1 && (
                <Flex w="33.33%" flexDir="column" gap={10}>
                  <Flex flexDir="column" justifyContent="flex-start">
                    {featured[1].mainImage && (
                      <BlogImageComponent value={featured[1].mainImage} isInline={false} />
                    )}
                    <Text
                      mt={2}
                      mb={1}
                      textAlign="left"
                      fontSize="12pt"
                      color="#787878"
                      fontWeight={400}
                    >
                      {featured[1].authorName}
                    </Text>
                    <Title
                      fontWeight={700}
                      textAlign="left"
                      m={0}
                      fontSize={{
                        base: '14pt',
                      }}
                    >
                      {featured[1].title}
                    </Title>
                    <Flex mt={2}>
                      <Link onClick={() => jump(featured[1].slug.current)} fontSize="13pt">
                        Read More
                      </Link>
                    </Flex>
                  </Flex>
                  {featured[2] && (
                    <Flex flexDir="column">
                      {featured[2].mainImage && (
                        <BlogImageComponent value={featured[2].mainImage} isInline={false} />
                      )}
                      <Text
                        textAlign="left"
                        fontSize="12pt"
                        color="#787878"
                        fontWeight={400}
                        mt={2}
                        mb={1}
                      >
                        {featured[2].authorName}
                      </Text>
                      <Title
                        fontWeight={700}
                        textAlign="left"
                        m={0}
                        fontSize={{
                          base: '14pt',
                        }}
                      >
                        {featured[2].title}
                      </Title>
                      <Flex mt={2}>
                        <Link onClick={() => jump(featured[2].slug.current)} fontSize="13pt">
                          Read More
                        </Link>
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default Blog;
