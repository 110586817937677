import { Flex, Link, Text } from '@chakra-ui/react';
import { IconContext } from 'react-icons';
import { PiFileThin } from 'react-icons/pi';

import { LEAssetProps } from '../Sanity/Model';

const Asset = (props: { asset: LEAssetProps }) => {
  const { asset } = props;
  return (
    <>
      <Flex justifyContent="flex-start" direction="row" alignItems="start" gap={1}>
        <IconContext.Provider
          value={{
            size: '28px',
            style: {
              marginTop: 8,
            },
          }}
        >
          <PiFileThin />
        </IconContext.Provider>
        <Flex justifyContent="flex-start" direction="column" alignItems="start">
          <Text
            ms={0}
            fontSize={{
              base: '21pt',
            }}
            fontWeight={500}
            lineHeight={1.3}
            color="leaseEndBlueDark"
          >
            {asset.name}
          </Text>
          <Link
            href={asset.url}
            target="_blank"
            fontSize={{
              base: '11pt',
            }}
            fontWeight={500}
            lineHeight={1.3}
            color="oceanBoatBlue"
          >
            Download
          </Link>
        </Flex>
      </Flex>
      <Flex
        justifyContent="flex-start"
        direction="row"
        alignItems="center"
        borderTopWidth={1}
        borderTopColor="#eee"
        mt={2}
        pt={2}
      >
        <Text
          fontSize={{
            base: '13pt',
          }}
          fontWeight={400}
          lineHeight={1.3}
          color="leaseEndBlueDark"
        >
          {asset.description}
        </Text>
      </Flex>
    </>
  );
};

export default Asset;
