import { Box, Image } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Background404, Foreground404 } from '../assets/Images';
import Title from '../components/Title';
import { Link } from '../components/ui/links/Link';
import { FAQS } from '../constants/urls';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';

const PageFor404s = () => {
  const { pathname } = useLocation();
  const alreadyRan = useRef(false);

  useEffect(() => {
    if (alreadyRan.current || !pathname) {
      return;
    }

    rudderanalytics.track(RudderEvent.Error, {
      error_message: '404 Not Found',
      pathname,
    });

    alreadyRan.current = true;
  }, [pathname]);

  return (
    <Box>
      <Box mt={{ sm: '50px', md: '100px', xl: '50px' }} textAlign="center">
        <Title>404 Not Found</Title>
        <Box fontSize="16px">
          Oops, looks like we took a wrong turn! Get back on track by checking our{' '}
          <Link fontSize="16px" color="oceanBoatBlue" textDecoration="underline" to={FAQS}>
            FAQ's
          </Link>
          .
        </Box>
      </Box>
      <Box overflow="hidden" p="0" zIndex="0" position="relative" m="0 -14px">
        <Image
          src={Background404}
          mr={{ sm: '250px', md: 'unset' }}
          minW={{ sm: '225vw', md: '200vw', lg: '101vw' }}
        />
        <Image
          src={Foreground404}
          w={{ sm: '95%', md: '40%' }}
          position="absolute"
          left={{ sm: '5%', md: '30%', lg: '25%' }}
          bottom="0px"
        />
      </Box>
    </Box>
  );
};

export default PageFor404s;
