import React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { CarPhoneLady } from '../../../../assets/Images';
import { MidPageBannerProps } from '../MobileComponents/MobileMidPageBanner';
import Odometer from '../Odometer';

const DesktopMidPageBanner: React.FC<MidPageBannerProps> = ({ data, ...props }) => {
  return (
    <Flex
      bgColor="headlightLadyBlue"
      h="150px"
      color="white"
      fontSize="24px"
      position="relative"
      justifyContent="center"
      gap="25px"
      alignItems="center"
      {...props}
    >
      <Image src={CarPhoneLady} alt="Car Phone Lady" h="322px" />
      <Flex flexDir="column">
        <Box>We've helped</Box>
        <Flex>
          {data ? (
            <Box color="driversYellow" fontSize="50px" lineHeight="64px" mt="-15px" mb="0px">
              {data}
            </Box>
          ) : (
            <Odometer ml="0px" />
          )}
          {data && (
            <Box ml={data ? '8px' : ''} h="64px" pt={data ? '4px' : '8px'}>
              drivers
            </Box>
          )}
        </Flex>
        <Box mt={data ? '-30px' : ''}>
          {!data && 'drivers '}end their {data ? 'vehicle ' : ''}leases.
        </Box>
      </Flex>
    </Flex>
  );
};

export default DesktopMidPageBanner;
