import { useField } from 'formik';
import React from 'react';
import { default as RCSlider } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';

const SliderWrapper = styled.div`
  padding-bottom: 40px;
`;

const StyledSlider = styled(RCSlider)`
  &&&& {
    .rc-slider-dot,
    .rc-slider-handle,
    .rc-slider-mark-text {
      cursor: default;
    }

    .rc-slider-track,
    .rc-slider-handle {
      background-color: ${({ theme }) => theme.colors.yellow};
      border-color: white;
    }

    .rc-slider-dot-active {
      border-color: ${({ theme }) => theme.colors.yellow};
    }
  }
`;

export type SliderProps = {
  name: string;
  step?: number | null;
  min: number;
  max: number;
  marks?: { [x: number]: string };
  onChange?: (val: number) => void;
  value?: number;
};

const Slider: React.FC<SliderProps> = ({
  step = null,
  min,
  max,
  marks,
  onChange,
  value,
  ...props
}) => {
  const [helpers, , { setValue }] = useField(props.name);

  return (
    <SliderWrapper>
      <StyledSlider
        min={min}
        max={max}
        marks={marks}
        step={step}
        onChange={onChange ?? ((value) => setValue(value))}
        value={value ?? helpers.value}
      />
    </SliderWrapper>
  );
};

export default Slider;
