/* eslint-disable no-underscore-dangle */

/* eslint-disable no-unused-vars */
import { Box, Container, Flex, Link } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Description from '../components/Description';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import TitleContainer from '../components/TitleContainer';
import { BLOG } from '../constants/urls';
import Loading from './Loading';
import AuthorImageComponent from './Sanity/Author/AuthorImageComponent';
import { authorFetch } from './Sanity/Data';
import { AuthorProps, BlogPostProps, ptAuthorComponents } from './Sanity/Model';

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [author, setAuthor] = useState<AuthorProps | null>(null);
  const [ref, setRef] = useState<string | null>(null);
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const fetch = async () => {
    setLoading(true);
    const data: AuthorProps | null = await authorFetch(slug, 'recent');
    setLoading(false);
    setAuthor(data);
  };

  useEffect(() => {
    if (location.search.includes('?ref=')) {
      setRef(window.location.href.split('?ref=')[1]);
    }
    fetch();
  }, [location]);

  return (
    <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" px={{ md: 16 }}>
      {loading ? (
        <Loading />
      ) : author ? (
        <>
          <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            maxWidth={760}
            mx="auto"
          >
            {ref && (
              <Box>
                <Link href={ref}>Back</Link>
              </Box>
            )}
            {/* Post title */}
            <TitleContainer alignContent="center" justifyContent="flex-start" flexDirection="row">
              <Title textAlign="left" fontSize={{ base: '36px', md: '52px' }}>
                {author.authorName || 'no name'}
              </Title>
            </TitleContainer>
            {/* Author */}
            <Box>
              {author.authorImage && <AuthorImageComponent value={author.authorImage} isInline />}
              {author.authorName}
            </Box>
            {/* Post body */}
            <Flex
              flexDirection="row"
              width="100%"
              alignItems="flex-start"
              alignContent="flex-start"
              textAlign="left"
              justifyContent="space-between"
            >
              <Description
                color="leaseEndBlue"
                textAlign="left"
                fontWeight="500"
                flexGrow={1}
                borderRightWidth={1}
                borderRightColor="#ccc"
              >
                <PortableText value={author.authorBio} components={ptAuthorComponents} />
              </Description>
              <Box width={200}>
                <Subtitle>Recent Articles</Subtitle>
                {author.posts
                  ? author.posts.map((post: BlogPostProps) => {
                      return (
                        <>
                          <Link href={`${BLOG}/${post.slug}`}>{post.title}</Link>
                          <p>{moment(new Date(post.publishDate)).format('MMM Do, YYYY')}</p>
                        </>
                      );
                    })
                  : null}
              </Box>
            </Flex>
          </Flex>
        </>
      ) : (
        <Box>Sorry, we couldn't load this post</Box>
      )}
    </Container>
  );
};

export default BlogPost;
