import { StyleConfig } from '@chakra-ui/react';

const button: StyleConfig = {
  baseStyle: {
    borderRadius: '0.3rem',
    fontWeight: 'bold',
    fontFamily: 'body',
    whiteSpace: 'break-spaces',
    textAlign: 'center',
  },
  sizes: {
    sm: { py: '10px', px: '20px', fontSize: '14px', lineHeight: '16px' },
    md: { py: '25px', px: '40px', fontSize: '14px', lineHeight: '16px' },
    lg: { py: '30px', px: '50px', fontSize: '16px', lineHeight: '20px' },
  },
  variants: {
    link: {
      fontSize: '14px',
      color: 'royalBlue',
      fontWeight: 'normal',
    },
    primary: {
      m: 0,
      bg: 'leaseEndYellow',
      color: 'grayDark',
      letterSpacing: '1px',
      _loading: {
        color: 'grayDark',
        opacity: '1',
        bg: 'yellowLight',
        _hover: {
          bg: 'yellowLight',
        },
      },
      _disabled: {
        color: 'oldSilver',
        bg: 'gainsboro',
        opacity: '1',
      },
      _hover: {
        bg: 'yellowLight',
        boxShadow: 'md',
        _disabled: {
          bg: 'gainsboro',
          boxShadow: 'none',
        },
      },
    },
    secondary: {
      m: 0,
      bg: 'white',
      color: 'royalBlue',
      fontWeight: 'bold',
      letterSpacing: '1px',
      border: '1px solid',
      textTransform: 'uppercase',
      _loading: {
        opacity: '0.7',
        bg: 'white',
        color: 'royalBlue',
        borderColor: 'oceanBoatBlue',
        _hover: {
          bg: 'white',
          color: 'royalBlue',
          borderColor: 'oceanBoatBlue',
        },
      },
      _disabled: {
        opacity: '1',
        color: 'taupeGray',
        borderColor: 'taupeGray',
      },
      _hover: {
        color: 'oceanBoatBlue',
        bg: 'azureishWhite',
        boxShadow: '0px 3px 6px #00000029',
        borderColor: 'oceanBoatBlue',
        textDecoration: 'none',
        _disabled: {
          bg: 'white',
          color: 'taupeGray',
          borderColor: 'taupeGray',
          boxShadow: 'none',
        },
      },
    },
    secondaryLink: {
      padding: '25px 40px',
      m: 0,
      bg: 'transparent',
      color: 'royalBlue',
      letterSpacing: '1px',
      _hover: {
        color: 'royalBlueLight',
        textDecoration: 'underline',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default button;
