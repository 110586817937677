/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, createContext } from 'react';

export const PaginationContext = createContext<{
  currentPage: number;
  itemsPerPage: number;
  totalRecords: number;
  maxPage: number;
  setItemsPerPage: (perPage: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  jumpToPage: (page: number) => void;
  setTotalRecords: (totalRecords: number) => void;
}>({
  currentPage: 1,
  itemsPerPage: 50,
  totalRecords: 0,
  maxPage: 0,
  setItemsPerPage: () => {},
  nextPage: () => {},
  prevPage: () => {},
  jumpToPage: () => {},
  setTotalRecords: () => {},
});

const FIRST_PAGE = 0;

const usePagination = ({ initialItmesPerPage = 50 }: { initialItmesPerPage?: number }) => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const [itemsPerPage, setItemsPerPage] = useState(initialItmesPerPage);

  const maxPage = Math.ceil(totalRecords / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevCurrentPage) => Math.min(prevCurrentPage + 1, maxPage - 1));
  };

  const prevPage = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, FIRST_PAGE));
  };

  const jumpToPage = (page: number) => {
    const pageNumber = Math.min(Math.max(FIRST_PAGE, page), maxPage);
    setCurrentPage(() => pageNumber);
  };

  return {
    currentPage,
    itemsPerPage,
    totalRecords,
    maxPage,
    setItemsPerPage,
    nextPage,
    prevPage,
    jumpToPage,
    setTotalRecords,
  };
};

export default usePagination;
