import { Box, Heading } from '@chakra-ui/react';

import MonthlyPaymentCalculator from '../../../components/MonthlyPaymentCalculator/MonthlyPaymentCalculator';

const MonthlyPayment = () => {
  return (
    <Box flexDir="column" p={{ base: '10px', md: '25px 10%' }}>
      <Heading fontWeight="semibold" fontSize={{ base: 25, md: 35 }} textAlign="center" mb="10px">
        Predict a New Monthly Payment.
      </Heading>
      <Box textAlign="center" fontSize="18px" mb="10px">
        Use our calculator below for an estimate.
      </Box>
      <MonthlyPaymentCalculator maxW={{ lg: '1500px' }} />
    </Box>
  );
};

export default MonthlyPayment;
