import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
        @font-face {
            font-family: LicensePlateFont;
            src: url('/fonts/license_plate_usa/LicensePlateUsa-Regular.ttf') format('truetype');
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-BlackItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-BlackItalic.otf') format('opentype');
            font-weight: 900;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Black.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-Black.otf') format('opentype');
            font-weight: 900;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-ExtraBoldItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-ExtraBoldItalic.otf') format('opentype');
            font-weight: 800;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-ExtraBold.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-ExtraBold.otf') format('opentype');
            font-weight: 800;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-BoldItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-BoldItalic.otf') format('opentype');
            font-weight: 700;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Bold.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-Bold.otf') format('opentype');
            font-weight: 700;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-SemiBoldItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-SemiBoldItalic.otf') format('opentype');
            font-weight: 600;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-SemiBold.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-SemiBold.otf') format('opentype');
            font-weight: 600;
            font-style: normal;
            font-display: swap;
        }


        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-MediumItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-MediumItalic.otf') format('opentype');
            font-weight: 500;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Medium.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-Medium.otf') format('opentype');
            font-weight: 500;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Italic.woff2') format('woff2'),
                    url('/fonts/monorale/Monorale-Italic.otf') format('opentype');
            font-weight: 400;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Regular.woff2') format('woff2'),
                    url('/fonts/monorale/Monorale-Regular.otf') format('opentype');
            font-weight: 400;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-LightItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-LightItalic.otf') format('opentype');
            font-weight: 300;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Light.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-Light.otf') format('opentype');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-ExtraLightItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-ExtraLightItalic.otf') format('opentype');
            font-weight: 200;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-ExtraLight.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-ExtraLight.otf') format('opentype');
            font-weight: 200;
            font-style: normal;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-ThinItalic.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-ThinItalic.otf') format('opentype');
            font-weight: 100;
            font-style: italic;
            font-display: swap;
        }

        @font-face {
            font-family: Monorale;
            src: url('/fonts/monorale/Monorale-Thin.woff2') format('woff2'),
                 url('/fonts/monorale/Monorale-Thin.otf') format('opentype');
            font-weight: 100;
            font-style: normal;
            font-display: swap;
        }
    `}
  />
);

export default Fonts;
