import { StyleConfig } from '@chakra-ui/react';

const pinInputTheme: StyleConfig = {
  variants: {
    outline: {
      color: 'charcoal',
      border: '1px solid',
      borderColor: 'grayDarkBackground',
      fontWeight: 'medium',
      borderRadius: '3px',
      padding: '10px',
      mx: '5px',
      bg: 'white',
      _focusVisible: {
        borderColor: 'oceanBoatBlue',
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    md: {
      fontSize: '16px',
    },
    lg: {
      fontSize: '18px',
      height: '40px',
    },
  },
};

export default pinInputTheme;
