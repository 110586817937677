import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Subtitle: React.FC<TextProps> = ({ children, ...props }) => (
  <Text
    color="leaseEndBlue"
    fontWeight="medium"
    mb={{ base: '10px' }}
    mt={{ lg: '20px' }}
    mx={{ lg: 'auto' }}
    fontSize={{ base: '16px', lg: '18px' }}
    maxW={{ lg: '650px' }}
    {...props}
  >
    {children}
  </Text>
);

export default Subtitle;
