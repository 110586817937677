import { gql } from '@apollo/client/core';

export const carDetailLicensePlate = gql`
  query carDetailLicensePlate($license_plate_state: String!, $license_plate_number: String!) {
    carDetailLicensePlate(
      license_plate_state: $license_plate_state
      license_plate_number: $license_plate_number
    ) {
      year
      make
      model
      vin
      fuel_type
      vehicle_type
    }
  }
`;

export const carDetailVin = gql`
  query carDetailVin($vin: String) {
    carDetailVin(vin: $vin) {
      year
    }
  }
`;
