import { Box, Button, Flex, Image, Checkbox, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import validateVin from '../../utils/validation/vinValidator';
import { CarEKG, HealthTracker as HealthTrackerIcon } from '../../assets/Images';
import { Input, NumberInput } from '../../components/formComponents';
import DateInput from '../../components/formComponents/DateInput';
import TrimSelect from './TrimSelect';
import DecodedVinBox from './DecodedVinBox';
import { validateFuture } from '../../utils/validation/dates';
import { INVALID_VIN_MSG } from '../../constants/vin';

const validationSchema = Yup.object({
  vin: Yup.string()
    .required('VIN is required')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
  trim: Yup.string().required('Trim is required'),
  mileage: Yup.number()
    .required('Mileage is required')
    .test('is-positive', 'Mileage is required', (value) => !!value && value > 0),
  lease_end_date: Yup.date()
    .required('Lease End Date is required')
    .test('payoff.good_through_date', 'Lease End Date should be in the future', validateFuture),
  monthly_payment: Yup.number()
    .required('Monthly Payment is required')
    .test(
      'is-more-than-9.99',
      'Surely your Monthly Payment is higher than $9.99',
      (value) => !!value && (value === 0 || value > 9.99),
    )
    .test(
      'is-less-than-10000',
      'Your monthly payment is more than $10,000, seriously?',
      (value) => !!value && value < 10000,
    ),
  email: Yup.string().email('Invalid email').required('Email is required'),
  opt_in: Yup.boolean(),
});

type FormFields = Yup.InferType<typeof validationSchema>;

// temporary function to avoid formik warning
const doNothing = () => {
  // do nothing
};

const HealthTracker = () => {
  return (
    <>
      <Flex p="1%" minW="100vw">
        <Box
          w={{ base: '100%', xl: '1350px' }}
          m={{ base: '0 auto' }}
          p={{ base: '25px', lg: '30px' }}
          bgColor="white"
          mb="30px"
        >
          <Flex flexDir={{ base: 'column', lg: 'row' }} mb="20px" justifyContent="space-between">
            <Image src={HealthTrackerIcon} w={{ base: '100%', md: '45%' }} ml="2.5%" mr="2.5%" />
            <Box w={{ base: '100%', lg: '50%' }} p="2.5% 5% 0">
              Get a FREE report on the status of your leased vehicle. Lease End provides you insight
              on the health of your lease, your vehicle's projected equity and even the best day to
              buy you lease out.
            </Box>
          </Flex>
          <Flex flexDir={{ base: 'column-reverse', lg: 'row' }} w="100%">
            <Box minW={{ base: '90%', lg: '55%' }} maxW="100%" p={{ base: '0', md: '0 10% 0 5%' }}>
              <Formik<FormFields>
                enableReinitialize
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                  vin: '',
                  trim: '',
                  mileage: 0,
                  lease_end_date: new Date(),
                  monthly_payment: 0,
                  email: '',
                  opt_in: false,
                }}
                validationSchema={validationSchema}
                onSubmit={doNothing}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <Input
                        name="vin"
                        placeholder="VIN"
                        label="TO GET STARTED, ENTER YOUR VIN"
                        mb={2}
                      />
                      <DecodedVinBox vin={values.vin} />
                      <TrimSelect vin={values.vin} name="trim" />
                      <NumberInput
                        name="mileage"
                        placeholder="Mileage"
                        label="Current Mileage"
                        mb={2}
                        showThousandSeparator
                        isWhole
                      />
                      <DateInput
                        hideCalendar
                        name="lease_end_date"
                        label="LEASE END DATE"
                        showPopperArrow={false}
                        alignSelf="flex-start"
                        dateFormat="MM/yyyy"
                        mask="99/9999"
                        maskPlaceholder="MM/YYYY"
                        mb={2}
                        monthAndYearOnly
                      />
                      <NumberInput
                        textAlign="center"
                        label="MONTHLY PAYMENT"
                        name="payoff"
                        placeholder="Monthly Payment"
                        isMoney
                        type="decimal"
                        labelProps={{ alignSelf: { base: 'center', md: 'start' }, mx: 0 }}
                        isDisabled={false}
                        mb={2}
                      />
                      <Input
                        _container={{
                          mb: 2,
                        }}
                        name="email"
                        placeholder="Email"
                        label="EMAIL ADDRESS"
                        mb={2}
                      />
                      <Checkbox
                        name="opt_in"
                        isChecked={values.opt_in}
                        onChange={(e) => {
                          setFieldValue('opt_in', e.target.checked);
                        }}
                        colorScheme="green"
                        size="md"
                        w={{ base: '90%', md: '60%', lg: '95%', xl: '72%' }}
                        m={{ base: '0 5% 6px', md: '0 20% 8px', lg: '0 5% 8px', xl: '0 14% 8px' }}
                        fontSize="8pt"
                      >
                        ALSO SEND ME FREE QUARTERLY CHECK-UPS
                      </Checkbox>
                      <Text w="100%" mb="8px" fontSize="8pt" textAlign="center">
                        That's right, we will send you a quarterly check-up so you can make sure
                        your lease status is healthy.
                      </Text>
                      <Button type="submit" w="95%" m="6px 2.5%">
                        SEND MY LEASE CHECK-UP
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
            <Image
              display={{ base: 'none', md: 'block' }}
              src={CarEKG}
              w={{ base: '80%', lg: '53%' }}
              m={{ lg: '-8% 0px 0px -7%', base: '0 auto -45px' }}
            />
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default HealthTracker;
