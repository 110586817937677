import React from 'react';
import { FormLabel, FormLabelProps } from '@chakra-ui/react';

export interface LabelProps extends FormLabelProps {
  noMargin?: boolean;
}

const Label: React.FC<LabelProps> = ({ noMargin, textAlign = 'left', children, ...props }) => (
  <FormLabel
    textAlign={textAlign}
    mb={noMargin ? 0 : '5px'}
    color="leaseEndBlue"
    fontWeight="semibold"
    letterSpacing="1px"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </FormLabel>
);

export default Label;
