import { Box, useBreakpointValue } from '@chakra-ui/react';

import DesktopMidPageBanner from './DesktopComponents/DesktopMidPageBanner';
import MobileMidPageBanner from './MobileComponents/MobileMidPageBanner';

const MidPageBanner = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box mt="175px" mb="20px">
      {isMobile ? (
        <MobileMidPageBanner bg="matisseBlue" />
      ) : (
        <DesktopMidPageBanner bg="matisseBlue" />
      )}
    </Box>
  );
};

export default MidPageBanner;
