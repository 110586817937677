import { Container, Loader } from 'semantic-ui-react';
import { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import useDevice, { DevicesEnum } from '../../../hooks/useDevice';
import CustomerReview from './CustomerReview';
import { useGetVisibleReviewsQuery } from '../../../gql/generated/graphql';
import { RequiredReview } from '../../../pages/Reviews';

/* eslint-disable import/no-unresolved */
import 'swiper/css';
import 'swiper/css/navigation';
/* eslint-enable */

const StyledSwiper = styled(Swiper)`
  & .swiper-button-prev,
  & .swiper-button-next {
    color: ${({ theme }) => theme.colors.yellow};
  }

  & .swiper-button-prev {
    left: -2px;
  }

  & .swiper-button-next {
    right: -2px;
  }
`;

const CustomerReviewsSwiper = () => {
  const [reviewVisible, setReviewVisible] = useState<boolean>(false);
  const isMobile = useDevice() === DevicesEnum.mobile;
  const maxReviewLength = 300;

  const { data, loading } = useGetVisibleReviewsQuery();

  const getSlides = (slides: RequiredReview[]) => {
    if (isMobile) {
      return slides.map((review, index) => (
        <SwiperSlide key={review.id} virtualIndex={index}>
          {({ isVisible }) => (
            <CustomerReview
              reviewer={review}
              transition="max-height 0.5s ease-out"
              maxH={isVisible ? '1000px' : '0px'}
            >
              {reviewVisible ? review.review : review.review.slice(0, maxReviewLength)}{' '}
              {review.review.length > maxReviewLength && (
                <Text textDecoration="underline" onClick={() => setReviewVisible(!reviewVisible)}>
                  {reviewVisible ? 'see less' : '...see more'}
                </Text>
              )}
            </CustomerReview>
          )}
        </SwiperSlide>
      ));
    }

    const jsxSlides = [];
    for (let i = 0; i < slides.length; i += 2) {
      const slide = (
        <SwiperSlide key={i} virtualIndex={i}>
          {({ isVisible }) => (
            <Flex
              justifyContent="start"
              transition="max-height 0.5s ease-out"
              maxH={isVisible ? '1000px' : '0px'}
            >
              <CustomerReview
                reviewer={slides[i as number]}
                desktopWidth={!isMobile ? '50%' : undefined}
              >
                {slides[i as number].review}
              </CustomerReview>
              {i !== slides.length - 1 && (
                <>
                  <Box borderLeft="2px solid" borderColor="grayBackground" />
                  <CustomerReview
                    reviewer={slides[(i as number) + 1]}
                    desktopWidth={!isMobile ? '50%' : undefined}
                  >
                    {slides[i + 1].review}
                  </CustomerReview>
                </>
              )}
            </Flex>
          )}
        </SwiperSlide>
      );
      jsxSlides.push(slide);
    }

    return jsxSlides;
  };

  return (
    <Container>
      {loading && <Loader active inline size="medium" />}
      {!loading && !!data?.visibleReviews?.length && (
        <>
          <StyledSwiper modules={[Virtual, Navigation]} spaceBetween={30} navigation virtual>
            {getSlides(data.visibleReviews as RequiredReview[])}
          </StyledSwiper>
        </>
      )}
    </Container>
  );
};

export default CustomerReviewsSwiper;
