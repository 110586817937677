import { css } from 'styled-components';

export const size = {
  base: 0,
  sm: 320,
  md: 768,
  lg: 1024,
  xl: 1200,
  '2xl': 1400,
};
export const theme = {
  device: {
    base: `(min-width: ${size.base}px)`,
    sm: `(min-width: ${size.sm}px)`,
    md: `(min-width: ${size.md}px)`,
    lg: `(min-width: ${size.lg}px)`,
    xl: `(min-width: ${size.xl}px)`,
    '2xl': `(min-width: ${size['2xl']}px)`,
  },
  colors: {
    apricotOrange: '#e58719',
    apricotOrangeLight: '#f0962b',
    bostonBlue: '#367BBF',
    white: '#FFFFFF',
    yellow: '#f4c53e',
    yellowLight: '#F7D267',
    yellowDark: '#bd9500',
    royalBlue: '#007dc5',
    royalBlueLight: '#5aacf8',
    royalBlueDark: '#005194',
    leaseEndBlue: '#002D5D',
    midnightBlueLight: '#3c558b',
    midnightBlueDark: '#000133',
    cobuyerBlue: '#327CBF',
    magenta: '#883A89',
    magentaLight: '#AA48AB',
    green: '#18B89A',
    greenPayoff: '#2AAB87',
    greenLight: '#61ebcb',
    greenDark: '#00876c',
    greenRadioBG: '#7DCBBB',
    greenRadio: '#2AAB87',
    greenRadioDark: '#1A7659',
    greenRadioBGLight: '#f4fafa',
    greenRadioBGLightPressed: '#eef8f5',
    grayRadio: '#666666',
    greenSilverTree: '#53A889',
    greenTradewindLight: '#70C2AE',
    greenTradewind: '#70C2AE29',
    gray: '#414042',
    grayLight: '#6c6b6d',
    grayLightBackground: '#F0F0F0',
    grayMedium: '#707070',
    grayMediumBackground: '#E0E0E0',
    grayDarkBackground: '#BDBCBC',
    grayDark: '#1b1a1b',
    grayBackground: '#F2F2F2',
    grayInput: 'rgba(34,36,38,0.15)',
    darkCharcoal: '#333333',
    orange: '#EDC659',
    pink: '#e067c2',
    red: '#DB3832',
    darkRed: '#AF1831',
    grayHover: 'rgba(0,0,0,0.05)',
    transparent: 'rgba(0, 0, 0, 0)',
    anchorTagBlue: '#4183c4',
    platinum: '#E6E6E6',
    gold: '#F9D05D',
    darkGold: '#FBC926',
    silver: '#CCCCCC',
    darkSilver: '#B3B3B3',
    bronze: '#C99969',
    darkBronze: '#CB9552',
  },
  boxShadow: {
    card: '0px 3px 6px #00000029',
    button: '1px 3px 8px #00000029',
    dot: '3px 3px 8px 3px #00000029',
    modal: '1px 0px 3px #00000029',
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  fontFamilies: {
    main: 'Monorale',
  },
  headers: {
    h1: {
      fontSize: '48px',
      fontWeight: 'thin',
    },
    h2: {
      fontSize: '32px',
      fontWeight: '400',
    },
    h3: {
      fontSize: '20px',
      fontWeight: '400',
    },
    h4: {
      fontSize: '16px',
      fontWeight: '300',
    },
    // all caps
    h5: {
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: '1.5px',
    },
  },
  p: {
    fontSize: '14px',
    fontWeight: '400',
  },
  // caption,links
  p2: {
    fontSize: '12px',
    fontWeight: '400',
  },
};

export const fullWidth = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;
