import { gql } from '@apollo/client';

export const payoffQuery = gql`
  query payoff(
    $state: STATES_ABBREVIATIONS!
    $currentOdometer: Float!
    $inquiryType: InquiryType!
    $inquiryKey: String!
    $slug: String!
  ) @api(name: prs) {
    payoff(
      state: $state
      currentOdometer: $currentOdometer
      inquiryType: $inquiryType
      inquiryKey: $inquiryKey
      slug: $slug
    ) {
      grossPayoffAmount
      netPayoffAmount
      goodThroughDate
      includesSalesTax
    }
  }
`;
