import { Box, BoxProps, Flex, Img, Link, Text } from '@chakra-ui/react';
import { useContext, useState } from 'react';
import TagManager from 'react-gtm-module';

import { Arrow } from '../../../assets/Images';
import { ResetWsContext } from '../../../providers/AuthorizedApolloProvider';
import { resetLocalStorage } from '../../../services/localStorage';
import Subtitle from '../../Subtitle';
import StartOverModal from './StartOverModal';

interface Props extends BoxProps {
  enterFlow: () => void;
  canStartOver: boolean;
  startOver: () => void;
}

const ContinueContainer = ({ enterFlow, canStartOver, startOver, ...rest }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { resetWebSocket } = useContext(ResetWsContext);

  const handleClick = async () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'suppression',
      },
    });
    enterFlow();
  };

  return (
    <>
      <Box minW="300px" maxWidth="370px" {...rest}>
        <Link textDecoration="none !important" onClick={handleClick}>
          <Flex
            width="100%"
            minHeight="100px"
            bgColor="leaseEndYellow"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            padding={{ base: '10px 0 35px 0', md: '0 0 35px 0' }}
            margin="0 auto 20px"
            borderRadius="15px"
            boxShadow="md"
            _hover={{ bgColor: 'yellowLight' }}
          >
            <Img
              w="100px"
              marginBottom="5px"
              src={Arrow}
              marginTop="-45px"
              alt="Continue your end of lease process with Lease End"
            />
            <Subtitle>Continue where you left off</Subtitle>
          </Flex>
        </Link>
        {canStartOver && (
          <Text
            marginTop="-50px"
            fontSize="13px"
            fontWeight="semibold"
            color="white"
            textAlign="center"
          >
            OR YOU CAN{' '}
            <Text
              as="span"
              cursor="pointer"
              textDecoration="underline"
              onClick={() => setOpen(true)}
            >
              START OVER
            </Text>
          </Text>
        )}
      </Box>
      <StartOverModal
        open={open}
        setOpen={setOpen}
        handleClick={() => {
          startOver();
          resetLocalStorage({
            removeGuid: true,
            removeAcquisitionVehicleImages: true,
          });
          setOpen(false);
          resetWebSocket();
        }}
      />
    </>
  );
};

export default ContinueContainer;
