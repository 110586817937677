import React from 'react';
import { Center, CenterProps, Spinner, Text } from '@chakra-ui/react';

interface Props extends CenterProps {
  hideText?: boolean;
}

const Loader: React.FC<Props> = ({ hideText = false, ...props }) => (
  <Center mt={2} overflow="hidden" {...props}>
    <Spinner />
    {!hideText && <Text ml={2}>Loading...</Text>}
  </Center>
);

export default Loader;
