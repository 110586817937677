import { useEffect, useState } from 'react';
import { size } from '../utils/styles/theme';

export enum DevicesEnum {
  mobile = 'MOBILE',
  tablet = 'TABLET',
  desktop = 'DESKTOP',
  largeDesktop = 'LARGE_DESKTOP',
}

const useDevice = () => {
  const [device, setDevice] = useState('');

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      let deviceSize = DevicesEnum.mobile;

      if (window.innerWidth >= size.md) {
        deviceSize = DevicesEnum.tablet;
      }

      if (window.innerWidth >= size.lg) {
        deviceSize = DevicesEnum.desktop;
      }
      if (window.innerWidth >= size.xl) {
        deviceSize = DevicesEnum.largeDesktop;
      }

      setDevice(deviceSize);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return device;
};

export default useDevice;
