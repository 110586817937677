import { Box } from '@chakra-ui/react';
import { useState } from 'react';

import Footer from '../../components/Footer';
import LeasePeaceHow from './components/LeasePeaceHow';
import MeditatorContainer from './components/MeditatorContainer';
import Uploading from './components/Uploading';
import ValuableInsights from './components/ValuableInsights';
import { getRandomShortReview } from './utils';

const LeasePeace = () => {
  const [fakeUploading, setFakeUploading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const review = getRandomShortReview();
  return (
    <Box>
      {fakeUploading ? (
        <Uploading
          review={review}
          setFakeUploading={setFakeUploading}
          fakeUploading={fakeUploading}
        />
      ) : (
        <MeditatorContainer
          fakeUploading={fakeUploading}
          setFakeUploading={setFakeUploading}
          files={files}
          setFiles={setFiles}
        />
      )}
      <ValuableInsights />
      <LeasePeaceHow />
      <Footer bg="matisseBlue" color="white" _socialMediaIcon={{ color: 'white' }} />
    </Box>
  );
};

export default LeasePeace;
