/* eslint-disable react/no-array-index-key */
import { Box, HStack, Icon, IconButton, VStack, useBreakpointValue } from '@chakra-ui/react';
import { useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

import SwipeableBox from '../../../SwipeableBox';
import CarouselProgressBar from './CarouselProgressBar';
import VideoReview from './VideoReview';
import VideoThumbnail from './VideoThumbnail';

export type Action = 'autoPlay' | 'close' | 'next' | 'play' | 'prev';

const VideoReviewCarousel = () => {
  const names = ['William', 'Celine', 'Henry', 'Siobhan', 'Phillip', 'Michael', 'Steven'];
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * names.length));

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % names.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + names.length) % names.length);
  };

  const getZIndex = (distance: number) => {
    switch (Math.abs(distance)) {
      case 0:
        return 3;
      case 1:
        return 2;
      default:
        return 1;
    }
  };

  const getScaleFactor = (distance: number) => {
    switch (Math.abs(distance)) {
      case 0:
        return 1;
      case 1:
      case 6:
        return isMobile ? 0 : 0.75;
      default:
        return 0;
    }
  };

  const handleAction = (action: Action) => {
    switch (action) {
      case 'next':
        handleNext();
        break;
      case 'play':
        setIsVideoOpen(true);
        break;
      case 'autoPlay':
        handleNext();
        setIsVideoOpen(true);
        break;
      case 'prev':
        handlePrev();
        break;
      default:
        setIsVideoOpen(false);
    }
  };

  return (
    <SwipeableBox
      onSwipeLeft={() => handleAction('next')}
      onSwipeRight={() => handleAction('prev')}
      position="relative"
      py={10}
      bgColor="activeBlue"
      borderRadius="10px"
    >
      <VStack>
        <Box h={{ base: '300px', md: '450px' }}>
          {names.map((name, index) => {
            const distanceFromCurrent = index - currentIndex;
            // Adjust the position based on the distance from the currentIndex
            let position = distanceFromCurrent * 175;

            // Wraparound logic
            if (position > 375) position -= names.length * 175;
            else if (position < -375) position += names.length * 175;

            const zIndex = getZIndex(distanceFromCurrent);
            const scaleFactor = getScaleFactor(distanceFromCurrent);

            return (
              <Box key={`review-video-${index}`}>
                <Box
                  key={name}
                  w={{ base: '200px', md: '300px' }}
                  h={{ base: '300px', md: '450px' }}
                  position="absolute"
                  left="50%"
                  transform={`translateX(calc(-50% + ${position}px)) scale(${scaleFactor})`}
                  transition="transform 0.5s ease"
                  zIndex={zIndex}
                >
                  <VideoThumbnail
                    name={name}
                    isCurrent={!distanceFromCurrent}
                    handleAction={handleAction}
                    isNextVideo={
                      distanceFromCurrent === 1 || distanceFromCurrent === -(names.length - 1)
                    }
                  />
                </Box>
                {!distanceFromCurrent && (
                  <VideoReview handleAction={handleAction} isOpen={isVideoOpen} name={name} />
                )}
              </Box>
            );
          })}
        </Box>
        <HStack>
          <IconButton
            aria-label="previous"
            color="leaseEndYellow"
            variant="ghost"
            _hover={{ opacity: 0.5 }}
            fontSize={32}
            icon={<Icon as={MdKeyboardArrowLeft} />}
            onClick={handlePrev}
          />
          <CarouselProgressBar
            currStep={currentIndex}
            setIndex={setCurrentIndex}
            totalSteps={names.length}
          />
          <IconButton
            aria-label="next"
            variant="ghost"
            color="leaseEndYellow"
            _hover={{ opacity: 0.5 }}
            fontSize={32}
            icon={<Icon as={MdKeyboardArrowRight} />}
            onClick={handleNext}
          />
        </HStack>
      </VStack>
    </SwipeableBox>
  );
};

export default VideoReviewCarousel;
