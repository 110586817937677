import { Box, Flex, Image, ScaleFade, Text } from '@chakra-ui/react';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import {
  LeasePeaceBackground,
  LeasePeaceMeditator,
  LeasePeaceSymbol,
} from '../../../assets/Images';
import Modal from '../../../components/Modal';
import Nav from '../../../components/Nav/Nav';
import { getRandomShortReview } from '../utils';
import LeasePeaceContactInfo from './ContactInfo';
import KeepCalm from './KeepCalm';

interface MeditatorContainerProps {
  fakeUploading: boolean;
  setFakeUploading: Dispatch<SetStateAction<boolean>>;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
}

const MeditatorContainer: FC<MeditatorContainerProps> = ({
  fakeUploading,
  setFakeUploading,
  files,
  setFiles,
}) => {
  const review = getRandomShortReview();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box backgroundImage={LeasePeaceBackground} backgroundSize="cover" mt="-1px">
      <Box w="100%" mb={{ base: 'unset', lg: '20px' }}>
        <Nav />
      </Box>
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        w={{ base: '100%', xl: '95%' }}
        gap="10%"
        p={{ base: '0 10%', lg: '0 5%' }}
        justifyContent="center"
        m="0 auto"
      >
        {files.length > 0 ? (
          <ScaleFade in={files.length > 0}>
            <LeasePeaceContactInfo files={files} />
          </ScaleFade>
        ) : (
          <KeepCalm
            setIsOpen={setIsOpen}
            setFileUploaded={setFiles}
            setFakeUploading={setFakeUploading}
          />
        )}
        <ScaleFade in={!fakeUploading}>
          <Flex
            w="415px"
            display={{ base: 'none', lg: 'flex' }}
            flexDirection="column"
            justifyContent="center"
          >
            <Image src={LeasePeaceSymbol} alt="Lease Peace Symbol" />
            <Image src={LeasePeaceMeditator} alt="Lease Peace Meditator" mt="-32%" p="0 10%" />
            <Box textAlign="right" mt="-70px" mb="20px">
              <Text fontWeight="bold" fontSize="20px">
                {review.review}
              </Text>
              <Flex m="0 auto" alignItems="center" justifyContent="right">
                <Text fontSize="16px">{review.name}</Text>
                <Text
                  fontSize="18px"
                  bgColor={review.color}
                  color="white"
                  ml="5px"
                  p="5px"
                  textAlign="center"
                  borderRadius="50%"
                >
                  {review.letters}
                </Text>
              </Flex>
              <Text fontSize="14px" color="royalBlue">
                Read more
              </Text>
            </Box>
          </Flex>
        </ScaleFade>
      </Flex>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} motionPreset="slideInBottom">
        <Flex flexDir="column" bgColor="white">
          <Text fontSize="28px">Example Lease Agreement</Text>
          <Text fontSize="14px" mb="20px">
            Not all agreements are created equal. We need pages including amounts for things like
            <b>Gross Capitalized Cost</b>, <b>Residual Value</b>, and <b>Deprecation</b>
          </Text>
        </Flex>
      </Modal>
    </Box>
  );
};

export default MeditatorContainer;
