import { extendTheme } from '@chakra-ui/react';

import Button from './components/button';
import Checkbox from './components/checkbox';
import Container from './components/container';
import Input from './components/input';
import Link from './components/link';
import Menu from './components/menu';
import Modal from './components/modal';
import PinInput from './components/pinInput';
import Progress from './components/progress';
import Switch from './components/switch';
import Text from './components/text';
import Tooltip from './components/tooltip';
import breakpoints from './foundations/breakpoints';
import colors from './foundations/colors';
import fonts from './foundations/fonts';
import sizes from './foundations/sizes';
import styles from './styles';

const customTheme = extendTheme({
  styles,
  breakpoints,
  fonts,
  colors,
  sizes,
  components: {
    Button,
    Container,
    Tooltip,
    Progress,
    Modal,
    Input,
    PinInput,
    Link,
    Checkbox,
    Text,
    Menu,
    Switch,
  },
});

export default customTheme;
