import React from 'react';
import { Checkbox } from 'semantic-ui-react';

interface Props {
  label?: string | JSX.Element;
  onChange?: any;
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  name?: string;
}

const Toggle: React.FC<Props> = ({ label = '', ...props }) => {
  return <Checkbox label={label} {...props} toggle />;
};

export default Toggle;
