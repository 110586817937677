import { Box, Divider, Flex, Spinner, Text, VStack } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

import { PaymentEstimate } from '../../gql/generated/graphql';
import { getFormattedEstimate } from '../../utils/estimatedPayment';
import { formatMoney } from '../../utils/helpers';
import LicensePlateOrVinInput from '../LicensePlateOrVinInput';
import { FormFields } from './MonthlyPaymentCalculator';

interface CalculatorResultsProps {
  goBack: () => void;
  paymentEstimate: PaymentEstimate;
  isMobile?: boolean;
  optOutFlag?: boolean;
  parent?: HTMLDivElement | null;
}

const CalculatorResults = ({
  goBack,
  paymentEstimate,
  isMobile,
  optOutFlag = false,
  parent,
}: CalculatorResultsProps) => {
  const { values } = useFormikContext<FormFields>();

  useEffect(() => {
    if (paymentEstimate && isMobile) {
      parent?.scrollIntoView();
    }
  }, [paymentEstimate]);

  return (
    <Flex mt={8} flexDirection={{ base: 'column', md: 'row' }}>
      {!optOutFlag && (
        <VStack>
          {!values.vehicle_payoff ? (
            <Spinner my="auto" size="lg" color="leaseEndBlue" />
          ) : (
            <VStack
              bg="leaseEndGreen"
              rounded="md"
              borderWidth="1px"
              borderColor="gray"
              color="white"
            >
              <Box px={8} py={4}>
                <Box textAlign="center" overflow="auto">
                  <Text fontSize={13}>Estimated Monthly Payment</Text>
                  <Text fontSize={30} fontWeight="bold" style={{ whiteSpace: 'nowrap' }}>
                    {`${getFormattedEstimate(
                      paymentEstimate?.paymentEstimateLow ?? 0,
                      paymentEstimate?.paymentEstimateHigh ?? 0,
                    )}*`}
                  </Text>
                  <Text
                    fontSize={11}
                  >{`*${paymentEstimate?.estimatedAnnualPercentageRate} APR based on credit`}</Text>
                </Box>
                <Divider w="100%" bg="white" h="0.5px" borderWidth={0} my="5px" />
                <Box alignSelf="start" pl="4">
                  <Text fontSize={13}>Estimated Payoff</Text>
                  <Text fontSize={13} fontWeight="bold">
                    {formatMoney(values.vehicle_payoff)}
                  </Text>
                </Box>
                <Box alignSelf="start" pl="4">
                  <Text fontSize={13}>Down Payment</Text>
                  <Text fontSize={13} fontWeight="bold">
                    -{formatMoney(values.down_payment)}
                  </Text>
                </Box>
                <Box alignSelf="start" pl="4">
                  <Text fontSize={13}>Estimated taxes & fees</Text>
                  <Text fontSize={13} fontWeight="bold">
                    {formatMoney(paymentEstimate?.estimatedTaxesAndFees)}
                  </Text>
                </Box>
                <Box alignSelf="start" pl="4">
                  <Text fontSize={13}>Total Amount</Text>
                  <Text fontSize={13} fontWeight="bold">
                    {formatMoney(
                      values.vehicle_payoff +
                        (paymentEstimate?.estimatedTaxesAndFees ?? 0) -
                        values.down_payment,
                    )}
                  </Text>
                </Box>
              </Box>
              <Box
                py={3}
                bg="deepGreenCyanTurquoise"
                borderBottomLeftRadius={4}
                borderBottomRightRadius={4}
                width="full"
                as="button"
                onClick={goBack}
              >
                <Text textAlign="center" fontSize={10} color="white" fontWeight="bold">
                  START OVER AND RE-CALCULATE
                </Text>
              </Box>
            </VStack>
          )}
          <Text fontSize={10} w="90%" color="taupeGray" textAlign="center">
            This is an estimate. When we verify your info we'll give you an exact amount to the
            penny.
          </Text>
        </VStack>
      )}
      <Flex
        flexDirection="column"
        mt={{ base: 4, lg: 0 }}
        mx="auto"
        textAlign={{ base: 'center', lg: 'inherit' }}
      >
        <Box ml={{ base: 0, lg: '25px' }} mb="4">
          <Text
            fontSize={18}
            fontWeight="semibold"
            color="leaseEndBlue"
            textAlign={optOutFlag ? 'center' : undefined}
          >
            Enter Your License Plate or VIN below
          </Text>
          <Text color="leaseEndBlue" mt={2} textAlign={optOutFlag ? 'center' : undefined}>
            We'll find your vehicle info and work to get you the best rates to buy your leased car
            in just minutes!
          </Text>
        </Box>
        <LicensePlateOrVinInput
          autoFocus
          shoppingCart={{
            buyout: values.vehicle_payoff,
            credit_score: values.credit_score,
            down_payment: values.down_payment,
            loan_term: values.term,
            payment_estimate_low: paymentEstimate?.paymentEstimateLow ?? 0,
            payment_estimate_high: paymentEstimate?.paymentEstimateHigh ?? 0,
            estimated_fees: paymentEstimate?.estimatedTaxesAndFees ?? 0,
          }}
          zip={values.zip}
        />
      </Flex>
    </Flex>
  );
};

export default CalculatorResults;
