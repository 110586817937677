import { StyleConfig } from '@chakra-ui/react';

const checkbox: StyleConfig = {
  variants: {
    labelWithBg: {
      label: {
        textAlign: 'left',
        ml: 5,
      },
      icon: {
        animation: 'none',
        color: 'leaseEndGreen',
        position: 'absolute',
        w: 6,
        h: 6,
        left: '1px',
        bottom: '-1px',
        filter: 'drop-shadow(0px 1px 0px white)',
      },
      control: {
        position: 'relative',
        w: '1.25em',
        h: '1.25em',
        border: '0.15em solid',
        borderColor: 'leaseEndGreen',
        color: 'leaseEndGreen',
        borderRadius: 'base',
        bg: 'white',
        _checked: {
          bg: 'white',
          borderColor: 'leaseEndGreen',
          _hover: {
            bg: 'white',
            borderColor: 'leaseEndGreen',
          },
        },
        _hover: {
          bg: 'white',
          borderColor: 'leaseEndGreen',
        },
      },
    },
  },
  baseStyle: {
    control: {
      color: 'white',
      borderRadius: 'base',
      bg: 'white',
      _checked: {
        color: 'white',
        bg: 'leaseEndGreen',
        borderColor: 'leaseEndGreen',
        _hover: {
          bg: 'leaseEndGreen',
          borderColor: 'leaseEndGreen',
        },
      },
      _disabled: {
        _hover: {
          bg: 'gray.100',
          borderColor: 'gray.100',
        },
      },
      _hover: {
        bg: 'white',
        borderColor: 'leaseEndGreen',
      },
    },
  },
};

export default checkbox;
