import { GETTING_STARTED, flowBaseUrl } from '../constants/urls';
import { useCheckFlowStatusQuery, useUnlinkTemporaryInfoMutation } from '../gql/generated/graphql';
import { CookieKeys, useCookie } from './useCookie';

const useCheckFlowStatus = () => {
  const [guid, setGuid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);

  const [unlinkTemporaryInfo] = useUnlinkTemporaryInfoMutation();
  const { data: flowStatus, refetch } = useCheckFlowStatusQuery({
    fetchPolicy: 'cache-and-network',
    variables: { guid },
  });

  const flowGuid = flowStatus?.checkFlowStatus?.guid;
  const enterFlow = () => {
    if (flowGuid) {
      setGuid(flowGuid);
    }
    window.open(
      flowStatus?.checkFlowStatus?.url
        ? `${flowBaseUrl}${flowStatus?.checkFlowStatus?.url}`
        : GETTING_STARTED,
      '_self',
    );
  };

  const startOver = async () => {
    if (flowGuid) {
      await unlinkTemporaryInfo({ variables: { id: flowGuid } });
    }
    setGuid('');
    refetch();
  };

  const inFlow = accessToken || guid ? flowStatus?.checkFlowStatus?.inFlow ?? false : false;
  const canStartOver = !!flowStatus?.checkFlowStatus?.guid;
  const car = flowStatus?.checkFlowStatus?.car;

  return { inFlow, enterFlow, canStartOver, startOver, car };
};

export default useCheckFlowStatus;
