import * as Yup from 'yup';

import phoneRegExp from '../../utils/validation/phoneNumber';

export type OptOutFormFields = {
  first_name: string;
  last_name: string;
  note: string;
  email: string;
  phone_number: string;
  address_line: string;
  address_line_2: string;
  city: string;
  state: string;
  zip: string;
  opt_out_reason: string;
  email_opted_out: boolean;
  sms_opted_out: boolean;
  mail_opted_out: boolean;
  opt_in_at: string;
  opted_out_selection?: string;
};

export const optOutValidationSchema = Yup.object({
  email_opted_out: Yup.boolean(),
  sms_opted_out: Yup.boolean(),
  mail_opted_out: Yup.boolean(),
  opt_out_reason: Yup.string().required('Reason is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  note: Yup.string().max(60, 'Notes must be 60 characters or less'),
  email: Yup.string().when('email_opted_out', {
    is: true,
    then: (schema) => schema.email('Invalid email address').required('Email is required'),
  }),
  phone_number: Yup.string().when('sms_opted_out', {
    is: true,
    then: (schema) =>
      schema
        .required('Phone number is required')
        .matches(phoneRegExp, 'Please enter a valid phone number'),
  }),
  address_line: Yup.string().when('mail_opted_out', {
    is: true,
    then: (schema) => schema.required('Address is required'),
  }),
  address_line_2: Yup.string(),
  city: Yup.string().when('mail_opted_out', {
    is: true,
    then: (schema) => schema.required('City is required'),
  }),
  state: Yup.string().when('mail_opted_out', {
    is: true,
    then: (schema) => schema.required('State is required'),
  }),
  zip: Yup.string().when('mail_opted_out', {
    is: true,
    then: (schema) => schema.required('Zip is required'),
  }),
  opt_in_at: Yup.string().required('Term is required'),
}).test('one-checked', 'At least one option must be checked', (values) => {
  const { email_opted_out, sms_opted_out, mail_opted_out } = values;

  if (email_opted_out || sms_opted_out || mail_opted_out) {
    return true;
  }

  return new Yup.ValidationError(
    'You must opt out of at least one communication method',
    null,
    'opted_out_selection',
  );
});

export const initialValues = {
  email_opted_out: false,
  sms_opted_out: false,
  mail_opted_out: false,
  opt_out_reason: '',
  opt_in_at: '',
  note: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  address_line: '',
  address_line_2: '',
  city: '',
  state: '',
  zip: '',
};

interface Option {
  value: string;
  label: string;
}

export const optOutReasons: Option[] = [
  { value: '', label: 'Please select a reason' },
  { value: 'ended_lease', label: "I've already ended my lease, with Lease End or some other way" },
  { value: 'no_lease', label: "I don't have a lease" },
  { value: 'deceased', label: 'The person being contacted is deceased' },
  { value: 'uninterested', label: "Uninterested in Lease End's services" },
];

export const optOutTerms: Option[] = [
  { value: '', label: 'Please select a term' },
  { value: '3', label: '3 months from now' },
  { value: '6', label: '6 months from now' },
  { value: '9', label: '9 months from now' },
  { value: '12', label: '12 months from now' },
  { value: 'never', label: "Never, I don't wish to opt back in" },
];

export interface SelectProps {
  name: string;
  options: Option[];
  error: string;
}
