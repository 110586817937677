import { createStandaloneToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import { checkboxReCaptchaRef, invisibleReCaptchaRef, isReCaptchaEnabled } from '.';
import customTheme from '../../chakra';
import { LDFlags } from '../../constants/experiments';
import useFlag from '../../hooks/useFlag';
import { createErrorToast } from '../../utils/toast';

interface Props {
  type: 'invisible' | 'checkbox';
}

export const useReCaptcha = ({ type }: Props) => {
  const isReCaptchaFlagEnabled = useFlag(LDFlags.RE_CAPTCHA_ENABLED);

  const generateReCaptchaToken = useCallback(async () => {
    let token: string | undefined | null = null;

    if (isReCaptchaEnabled(isReCaptchaFlagEnabled)) {
      if (type === 'invisible') {
        token = await invisibleReCaptchaRef.current?.executeAsync();
        invisibleReCaptchaRef?.current?.reset();
      } else if (type === 'checkbox') {
        token = checkboxReCaptchaRef.current?.getValue(); // .executeAsync() is only for invisible
        checkboxReCaptchaRef?.current?.reset();
      }

      if (!token) {
        const { toast } = createStandaloneToast({ theme: customTheme });
        toast(
          createErrorToast({
            errorMessage: 'reCAPTCHA Error',
            title: 'There was an error with reCAPTCHA.',
          }),
        );
      }
    }

    return token;
  }, [isReCaptchaFlagEnabled]);

  return { generateReCaptchaToken };
};
