interface ReviewObj {
  review: string;
  name: string;
  color: string;
  letters: string;
}

const reviewsArray: ReviewObj[] = [
  {
    review: '“…no pressure. Easy to work with.”',
    name: 'Donald Albee',
    color: '#6D56AD',
    letters: 'DA',
  },
  {
    review: '“…very helpful and understanding”',
    name: 'Gail McDermott',
    color: '#A35C92',
    letters: 'GM',
  },
  {
    review: '“Courteous and professional…”',
    name: 'Loren Moberg',
    color: '#F36C31',
    letters: 'LM',
  },
  {
    review: '“…process is as smooth as butter”',
    name: 'Vincy N',
    color: '#F7931E',
    letters: 'VN',
  },
  {
    review: '“..the process was a breeze”',
    name: 'Heather M',
    color: '#F0C346',
    letters: 'HM',
  },
  {
    review: "“..I can't recommend this company enough”",
    name: 'Amy Smith',
    color: '#B6CF2E',
    letters: 'AS',
  },
];

export const getRandomShortReview = () => {
  const randomIndex = Math.floor(Math.random() * reviewsArray.length);
  return reviewsArray[randomIndex];
};
