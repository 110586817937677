import { FormControl, Flex, FormControlProps } from '@chakra-ui/react';
import Label from './Label';
import Input from './Input';

interface Props extends FormControlProps {
  name: string;
  inputWidth?: string;
  label?: string;
}

const CodeInput = ({ name, inputWidth, label = 'CODE', ...rest }: Props) => {
  return (
    <FormControl {...rest}>
      <Label textAlign="center" textTransform="none">
        {label}
      </Label>
      <Flex justifyContent="center">
        <Input
          w={inputWidth || '250px'}
          name={name}
          autoFocus
          maxLength={6}
          type="number"
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          _input={{
            textAlign: 'center',
            autoComplete: 'one-time-code',
          }}
        />
      </Flex>
    </FormControl>
  );
};

export default CodeInput;
