import { Box, Heading, Link, Text } from '@chakra-ui/react';

import {
  BUYING_VS_LEASING,
  NEGOTIATE_BUYOUT_PRICE,
  RESIDUAL_VALUE_QUICK,
} from '../../../constants/urls';

const TellMeMoreAboutLeasedBuyouts = () => {
  return (
    <Box
      my={{ base: 8, md: 10 }}
      textAlign="center"
      maxW="container.lg"
      mx="auto"
      fontSize="16px"
      px={4}
    >
      <Heading as="h2" fontWeight="semibold" fontSize={{ base: 25, md: 35 }} mb={8}>
        Tell me more about lease buyouts.
      </Heading>
      <Text mb={8}>Need more information? Check out our library of resources:</Text>
      <Link
        display="block"
        mb={2}
        href={NEGOTIATE_BUYOUT_PRICE}
        isExternal
        w="max-content"
        mx="auto"
      >
        Can you negotiate the buyout price of a leased car?
      </Link>
      <Link display="block" mb={2} href={RESIDUAL_VALUE_QUICK} isExternal w="max-content" mx="auto">
        A quick guide to residual value in leasing
      </Link>
      <Link display="block" href={BUYING_VS_LEASING} isExternal w="max-content" mx="auto">
        Buying vs. leasing
      </Link>
    </Box>
  );
};

export default TellMeMoreAboutLeasedBuyouts;
