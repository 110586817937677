import { Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';

const WhyBuyYourLeasedCar = () => {
  return (
    <Flex
      my={{ base: 8, md: 10 }}
      textAlign="center"
      maxW="container.lg"
      mx="auto"
      fontSize="16px"
      px={4}
      flexDir="column"
      gap={8}
    >
      <Heading as="h2" fontWeight="semibold" fontSize={{ base: 25, md: 35 }}>
        Why buy your leased car?
      </Heading>
      <Text>
        Instead of returning the car to the leasing company, you can choose to buy it and keep it as
        your own: financed by our financial partners, facilitated by Lease End.
      </Text>
      <Text>Here are just a few benefits of a buyout:</Text>
      <UnorderedList textAlign="left">
        <ListItem>
          <Text as="span" fontWeight="semibold">
            No mileage restrictions:
          </Text>{' '}
          Once you own the car, you can drive it as much as you want without worrying about excess
          mileage fees.
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="semibold">
            Familiarity:
          </Text>{' '}
          You already know the history and condition of the car, making it a more comfortable choice
          than purchasing a used car from a stranger.
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="semibold">
            Ownership:
          </Text>{' '}
          Buying your leased car means it’s yours — keep it forever or sell it down the road.
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="semibold">
            Convenience:
          </Text>{' '}
          No dealership or DMV trip necessary.
        </ListItem>
      </UnorderedList>
      <Text>
        No need to go through a bank, credit union, or online lender separately; our financing
        partners (including Ally Bank, TD Bank, and Capital One) will find an auto loan that's right
        for you.
      </Text>
      <Text>
        Picture this: you've successfully bought your leased car and are now the proud owner of a
        vehicle you know and love. Take some time to celebrate your decision and enjoy the freedom
        and flexibility that come with owning your car.
      </Text>
    </Flex>
  );
};

export default WhyBuyYourLeasedCar;
