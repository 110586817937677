import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import CircularCountdown from './CircularCountdown';
import { Action } from './VideoReviewCarousel';

interface Props {
  handleAction: (action: Action) => void;
  isOpen: boolean;
  name: string;
}

const VideoReview = ({ handleAction, isOpen, name }: Props) => {
  const video = `/video-reviews/videos/${name}.mp4`;
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  const playNext = () => {
    handleAction('autoPlay');
    setIsPlaying(true);
  };

  const cancelAutoPlay = () => {
    handleAction('close');
    setIsPlaying(true);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    rudderanalytics.track(RudderEvent.VideoReviewCompleted, {
      video: `${name}.mp4`,
    });
  };

  // Disable scrolling when the overlay is open
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Box
          position="fixed"
          top="0"
          left="0"
          boxSize="100%"
          bgColor="blackAlpha.800"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="9999"
          onClick={cancelAutoPlay}
        >
          <Box height={{ base: '440px', md: '720px' }} maxWidth={{ base: '330px', md: '540px' }}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              autoPlay
              controls
              onEnded={handleEnded}
              controlsList="nodownload noplaybackrate"
              disablePictureInPicture
              style={{
                border: '1px solid white',
                borderRadius: '5px',
                filter: isPlaying ? 'none' : 'blur(4px)',
                outline: '1px solid #015FCC', // trueBlue color - video component requires hex code
              }}
            >
              <source src={video} type="video/mp4" />
            </video>
          </Box>

          {!isPlaying && (
            <Box top="50%" left="50%" transform="translate(-50%, -50%)" position="absolute">
              <CircularCountdown
                message="Next Video In"
                seconds={5}
                onCancel={cancelAutoPlay}
                onPlayNext={playNext}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default VideoReview;
