import { Box, Flex, Image } from '@chakra-ui/react';

import { BBBLogo, FacebookLogo, FiveStars, GoogleLogo } from '../../../assets/Images';
import { BBB, FACEBOOK } from '../../../constants/urls';

const ReviewStars = ({
  img,
  includeStars = true,
  link,
  width,
  alt,
}: {
  img: string;
  includeStars?: boolean;
  link?: string;
  width: string;
  alt?: string;
}) => {
  return (
    <div>
      <Flex alignItems="flex-end" h="55px" pb="5px">
        <Box m="0 auto" borderRadius="8px" width={width}>
          {link ? (
            <a target="_blank" rel="noreferrer" href={link} aria-label={alt}>
              <Image m="0 auto" src={img} alt={alt} />
            </a>
          ) : (
            <Image m="0 auto" src={img} alt={alt} />
          )}
        </Box>
      </Flex>
      {includeStars && (
        <Image
          m="0 auto"
          src={FiveStars}
          alt="Lease End Receives 4.6 out of 5 star on Better Business Bureau"
        />
      )}
    </div>
  );
};

interface ReviewProps {
  width?: string;
}

export const BBBStars = ({ width }: ReviewProps) => {
  return (
    <ReviewStars
      link={BBB}
      img={BBBLogo}
      width={width || '125px'}
      alt="Lease End receives top ratings from Better Business Bureau"
    />
  );
};

export const GoogleStars = ({ width = '125px' }: ReviewProps) => (
  <ReviewStars
    img={GoogleLogo}
    width={width || '125px'}
    alt="Lease End receives top ratings from Google"
  />
);

export const FacebookLogoLink = ({ width }: ReviewProps) => (
  <ReviewStars
    img={FacebookLogo}
    link={FACEBOOK}
    width={width || '55px'}
    includeStars={false}
    alt="Lease End receives top ratings from Facebook"
  />
);

export default ReviewStars;
