import Big from 'big.js';

export const getEstimateLowAndHigh = (estimate: string) => {
  const paymentEstimateRegex = /\$([\d.]+)\s?-\s?\$([\d.]+)/;

  const payment_estimate_low = Number(paymentEstimateRegex.exec(estimate)?.[1]) ?? 0;
  const payment_estimate_high = Number(paymentEstimateRegex.exec(estimate)?.[2]) ?? 0;

  return { payment_estimate_low, payment_estimate_high };
};

export const getFormattedEstimate = (paymentEstimateLow: number, paymentEstimateHigh: number) => {
  if (paymentEstimateLow && paymentEstimateHigh)
    return `$${paymentEstimateLow.toFixed(0) ?? 0} - $${paymentEstimateHigh.toFixed(0) ?? 0}`;

  return '';
};

interface HighLtvRatioParams {
  payoff: number;
  estimatedTaxesAndFees: number;
  kbbRetailValue: number;
}

const LTV_RATIO_LIMIT = 1;

export const highLtvRatio = ({
  payoff,
  estimatedTaxesAndFees,
  kbbRetailValue,
}: HighLtvRatioParams) => {
  if (!payoff || !estimatedTaxesAndFees || !kbbRetailValue) return false;

  const ratio = new Big(payoff).plus(estimatedTaxesAndFees).div(kbbRetailValue).toNumber();

  return ratio >= LTV_RATIO_LIMIT;
};
