import { StyleConfig } from '@chakra-ui/react';

const progress: StyleConfig = {
  baseStyle: (props) => {
    const { colorScheme } = props;

    const getBgColor = () => {
      if (colorScheme === 'green') {
        return 'leaseEndGreen';
      }
      if (colorScheme === 'yellow') {
        return 'leaseEndYellow';
      }

      return `${colorScheme}.500`;
    };

    return {
      filledTrack: {
        bgColor: getBgColor(),
      },
    };
  },
};

export default progress;
