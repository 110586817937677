import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction } from 'react';

import { LeasePeaceMeditator, LeasePeaceSymbol, LeasePeaceVan } from '../../../assets/Images';
import AgreementDropzone from './AgreementDropzone';

interface KeepCalmProps {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setFileUploaded: Dispatch<SetStateAction<File[]>>;
  setFakeUploading: Dispatch<SetStateAction<boolean>>;
}

const KeepCalm: FC<KeepCalmProps> = ({ setIsOpen, setFileUploaded, setFakeUploading }) => {
  return (
    <Box w={{ base: '100%' }} maxW={{ lg: '50%', xl: '600px' }}>
      <Box textAlign={{ base: 'center', lg: 'left' }}>
        <Text
          fontSize={{ base: '20px', lg: '22px', xl: '24px' }}
          lineHeight={{ base: '20px', lg: '22px', xl: '24px' }}
          fontWeight="bold"
          mb={{ base: '10px', lg: 0, xl: '10px' }}
        >
          Lease Ending Soon?
        </Text>
        <Text
          fontSize={{ base: '48px', lg: '64px', xl: '72px' }}
          lineHeight={{ base: '56px', lg: '72px', xl: '80px' }}
          fontWeight="extrabold"
          mb={{ base: '10px', lg: 0, xl: '10px' }}
        >
          Keep Calm.
        </Text>
        <Text
          fontSize={{ base: '22px', lg: '28px', xl: '32px' }}
          lineHeight={{ base: '22px', lg: '28px', xl: '32px' }}
        >
          Achieve <b>Lease Peace</b>
        </Text>
      </Box>
      <Box p="0 25%" mt="20px" display={{ base: 'block', lg: 'none' }}>
        <Image src={LeasePeaceSymbol} alt="Lease Peace Symbol" />
        <Image src={LeasePeaceMeditator} alt="Lease Peace Meditator" mt="-32%" p="0 10%" />
      </Box>
      <Text
        fontSize="18px"
        p={{ base: '0 20px', lg: 0 }}
        mt={{ base: '-20px', lg: '10px', xl: '30px' }}
      >
        Upload your lease agreement and get a FREE estimate of your <b>vehicle's equity</b>, a{' '}
        <b>mileage limit projection</b> and a <b>monthly payment estimate</b> if you were to buy
        your vehicle.
      </Text>
      <AgreementDropzone setFileUploaded={setFileUploaded} setFakeUploading={setFakeUploading} />
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        textAlign={{ base: 'center', lg: 'left' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        mb={{ base: '40px', lg: 0 }}
      >
        <Text color="royalBlue" cursor="pointer" onClick={() => setIsOpen(true)}>
          Lease Agreement Examples
        </Text>
        <Text>
          Protected by <b>Google reCAPTCHA</b>
        </Text>
      </Flex>
      <Image
        src={LeasePeaceVan}
        alt="Groovy Leased Van"
        m="0 auto 50px"
        maxW="100%"
        display={{ base: 'none', lg: 'block' }}
      />
    </Box>
  );
};

export default KeepCalm;
