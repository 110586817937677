import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useKbbVinQuery } from '../../gql/generated/graphql';
import validateVin from '../../utils/validation/vinValidator';

interface DecodedVinBoxProps {
  vin: string;
}

const DecodedVinBox: React.FC<DecodedVinBoxProps> = ({ vin }) => {
  const { data } = useKbbVinQuery({
    variables: { vin },
    skip: !vin || vin.length < 17 || !validateVin(vin),
  });
  let decodedVin;
  if (data?.kbbVin?.vinResults) {
    decodedVin = data?.kbbVin?.vinResults[0];
  }
  return (
    <>
      {vin.length === 17 && (
        <Box bgColor="greenBackground" p="5px 10px" borderRadius="3px">
          <Text>
            <Text as="b">{`${decodedVin?.yearId} ${decodedVin?.makeName}`}</Text>{' '}
            {decodedVin?.modelName}
          </Text>
        </Box>
      )}
    </>
  );
};

export default DecodedVinBox;
