import { Box, Divider, Flex, Image, Text } from '@chakra-ui/react';

import {
  Dollaritas,
  GoogleReviews,
  GreenHandKeyWithOutline,
  Inc5000,
  Odometer,
  SparkAward,
  TrustPilotIcon,
} from '../../../assets/Images';

const ValuableInsights = () => {
  return (
    <Box mt={{ base: '20px', lg: '40px' }} textAlign="center">
      <Text fontSize={{ base: '28px', lg: '48px' }} fontWeight="600" mb="50px">
        Valuable insights help you make the right decisions
      </Text>
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        justifyContent="space-around"
        p={{ base: '0 10%', lg: '0 5%' }}
        w={{ base: '100%', lg: '100%', xl: '80%' }}
        m="0 auto"
        alignItems="center"
        textAlign="center"
        fontSize="18px"
      >
        <Flex w={{ base: '100%', lg: '250px', xl: '300px' }} flexDir="column" gap="30px">
          <Image src={Dollaritas} alt="Dollar Sign" m="0 auto" h="128px" w="128px" />
          <Text color="royalBlue" fontSize="28px">
            Know your equity
          </Text>
          <Text>
            Your leased vehicle's equity can be hard to calculate. Relax and let us handle it. If
            your vehicle has built equity buying your lease out could be a{' '}
            <b>really groovy financial move</b>
          </Text>
        </Flex>
        <Flex w={{ base: '100%', lg: '250px', xl: '300px' }} flexDir="column" gap="30px">
          <Image src={Odometer} alt="Odometer" m="0 auto" h="128px" w="275px" />
          <Text color="royalBlue" fontSize="28px">
            Mileage Limit
          </Text>
          <Text>
            Our mileage projection helps you plan and <b>stay under your limit</b>. Exceeding your
            limit can be costly at the dealership. Lease End doesn't charge you for overages
          </Text>
        </Flex>
        <Flex w={{ base: '100%', lg: '250px', xl: '300px' }} flexDir="column" gap="30px">
          <Image
            src={GreenHandKeyWithOutline}
            alt="A key in a hand"
            m="0 auto"
            h="128px"
            w="128px"
          />
          <Text color="royalBlue" fontSize="28px">
            Keep your vehicle
          </Text>
          <Text>
            <b>You don't have to return your leased car to the dealership!</b> After you upload your
            lease agreement we'll give you an estimated new monthly payment
          </Text>
        </Flex>
      </Flex>
      <Divider w="75%" m="50px auto 20px" />
      <Flex
        gap={{ base: '30px', xl: '100px' }}
        justifyContent="center"
        alignItems="center"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Image src={TrustPilotIcon} w="200px" h="90px" />
        <Image src={SparkAward} w="200px" h="90px" />
        <Image src={GoogleReviews} w="200px" h="80px" />
        <Image src={Inc5000} w="200px" h="80px" />
      </Flex>
      <Divider w="75%" m="20px auto 50px" />
    </Box>
  );
};

export default ValuableInsights;
