import React from 'react';
import { Avatar, Box, Flex, FlexProps, Image } from '@chakra-ui/react';
import { Quote } from '../../../assets/Images';
import Description from '../../Description';
import { capitalizeFirstLetter } from '../../../utils/text/text';
import { Review } from '../../../gql/generated/graphql';
import { NonNullRequired } from '../../../utils/types/typeHelpers';

interface Props extends FlexProps {
  children: React.ReactNode;
  reviewer: NonNullRequired<Review>;
  desktopWidth?: string;
  desktopMargin?: string;
}

const formatCustomerName = (name: string) => {
  if (!name) {
    return '';
  }

  const nameSplit = name.split(' ');
  const firstName = capitalizeFirstLetter(nameSplit[0].toLowerCase());

  if (nameSplit.length === 1) {
    return firstName;
  }

  const lastName = capitalizeFirstLetter(nameSplit[1].toLowerCase());

  return `${firstName} ${lastName}`;
};

const CustomerReview = ({ children, reviewer, desktopWidth, desktopMargin, ...props }: Props) => {
  return (
    <Flex
      p={{ base: '0.5rem 2.5rem 0.5rem 2.5rem', md: '0.5rem 2.5rem' }}
      w={{ md: desktopWidth }}
      m={{ base: '0 auto', md: desktopMargin }}
      textAlign="left"
      {...props}
    >
      <Image
        m="0 5px auto 0"
        w="25px"
        bottom="5px"
        src={Quote}
        alt="Hear why Lease End is the right company for you."
      />
      <Flex flexDir="column" w="100%">
        <Description display="block" fontSize="14px">
          {children}
        </Description>
        <Flex m="0 0 0 auto" fontSize="36px" justifyContent="center" alignItems="center">
          <Avatar size="md" name={reviewer.customer_name} />
          <Box m="15px 0px 0px 10px">
            <Description m="0 0 0 auto" fontWeight="bold" fontStyle="italic" fontSize="20px">
              {formatCustomerName(reviewer.customer_name)}
            </Description>
            {Boolean(reviewer.location) && (
              <Description fontSize="16px">{reviewer.location}</Description>
            )}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CustomerReview;
