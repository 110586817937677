import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { defineStyle } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleItem = defineStyle({
  fontFamily: 'body',
});

const baseStyleButton = defineStyle({
  fontFamily: 'body',
});

const baseStyle = definePartsStyle({
  item: baseStyleItem,
  button: baseStyleButton,
});

export default defineMultiStyleConfig({
  baseStyle,
});
