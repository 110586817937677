import { Box, BoxProps, Flex, FlexProps, Image } from '@chakra-ui/react';
import { useState } from 'react';
import SubjectCard from './SubjectCard';
import { NoDealership, OverMileage, PiggyBank, VehicleDamage } from '../../../../assets/Images';
import { damageText, dmvText, equityText, mileageText } from '../../utils';

interface DesktopHelpCardsProps {
  _subjectCardContainer?: FlexProps;
  _infoContainer?: BoxProps;
}

const DesktopHelpCards = ({ _subjectCardContainer, _infoContainer }: DesktopHelpCardsProps) => {
  const [longForm, setLongForm] = useState<string>(dmvText);
  return (
    <>
      <Flex justifyContent="space-between" fontSize="18px" {..._subjectCardContainer}>
        <SubjectCard handleHover={() => setLongForm(dmvText)} isActive={longForm === dmvText}>
          <Image src={NoDealership} alt="I don't want to go to the dealership or DMV" m="auto" />
          <Box textAlign="center">I don't want to go to the dealership or DMV.</Box>
        </SubjectCard>
        <SubjectCard handleHover={() => setLongForm(equityText)} isActive={longForm === equityText}>
          <Image src={PiggyBank} alt="I want to keep my car's equity." m="auto" />
          <Box textAlign="center">I want to keep my car's equity.</Box>
        </SubjectCard>
        <SubjectCard
          handleHover={() => setLongForm(mileageText)}
          isActive={longForm === mileageText}
        >
          <Image src={OverMileage} alt="I'm over my mileage limit." m="auto" maxW="200px" />
          <Box textAlign="center">I'm over my mileage limit.</Box>
        </SubjectCard>
        <SubjectCard handleHover={() => setLongForm(damageText)} isActive={longForm === damageText}>
          <Image src={VehicleDamage} alt="My car is damaged." m="auto" />
          <Box textAlign="center">My car is damaged.</Box>
        </SubjectCard>
      </Flex>
      <Box
        bgColor="white"
        boxShadow="md"
        borderRadius="10px"
        p={8}
        mt={4}
        mb="30px"
        fontSize="18px"
        {..._infoContainer}
      >
        {longForm}
      </Box>
    </>
  );
};

export default DesktopHelpCards;
