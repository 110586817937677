import { useKbbVinQuery } from '../../gql/generated/graphql';
import { Select } from '../../components/formComponents';
import validateVin from '../../utils/validation/vinValidator';

interface Props {
  vin?: string;
  name: string;
}

const TrimSelect = ({ vin, name }: Props) => {
  const { data: vinData } = useKbbVinQuery({
    variables: { vin: vin || '' },
    skip: !vin || vin.length < 17 || !validateVin(vin),
  });
  const options = Array.from(
    new Set(
      vinData?.kbbVin?.vinResults?.map((vinResult) => ({
        label: vinResult?.trimName || '',
        value: vinResult?.trimName || '',
      })),
    ),
  );

  return (
    <Select
      label="trim"
      name={name}
      placeholder="Select Trim"
      alignSelf="self-start"
      w="full"
      mb={2}
      options={options}
      menuListHeight="100px"
    />
  );
};

export default TrimSelect;
