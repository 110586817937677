/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Collapse, Container, Flex, Text, Textarea, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import ReCaptcha from '../../components/ReCaptcha';
import { useReCaptcha } from '../../components/ReCaptcha/useReCaptcha';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import { Checkbox, Input, Select } from '../../components/formComponents';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../../constants/experiments';
import { statesArray } from '../../constants/states';
import {
  OptEventSourceEnum,
  OptEventTypeEnum,
  useCreateOptEventMutation,
} from '../../gql/generated/graphql';
import useFlag from '../../hooks/useFlag';
import { addMonthsToDate } from '../../utils/dates';
import {
  OptOutFormFields,
  initialValues,
  optOutReasons,
  optOutTerms,
  optOutValidationSchema,
} from './utils';

const OptOut = () => {
  const { generateReCaptchaToken } = useReCaptcha({ type: 'checkbox' });
  const recaptchaEnabled = useFlag(LDFlags.RE_CAPTCHA_ENABLED);

  const [reCaptchaVerified, setReCaptchaVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [createOptEvent] = useCreateOptEventMutation({
    onCompleted: () => setSubmitted(true),
  });

  const handleSubmit = async (formValues: OptOutFormFields) => {
    const {
      opt_in_at,
      email_opted_out,
      sms_opted_out,
      mail_opted_out,
      address_line,
      address_line_2,
      opt_out_reason,
      opted_out_selection,
      ...values
    } = formValues;

    let optInAt: Date | null = null;
    const cleanedValues = Object.fromEntries(
      Object.entries(values).filter(([, value]) => value !== ''),
    );

    if (opt_in_at !== '' && opt_in_at !== 'never') {
      optInAt = addMonthsToDate(Number(opt_in_at));
    }

    const token = await generateReCaptchaToken();
    await createOptEvent({
      variables: {
        token,
        event: {
          ...cleanedValues,
          reason: opt_out_reason || null,
          address: `${address_line || ''} ${address_line_2 || ''}`.trim() || null,
          opt_in_at: optInAt,
          action: OptEventTypeEnum.OptOut,
          source: OptEventSourceEnum.Com,
        },
      },
    });
  };

  if (submitted) {
    return (
      <Container>
        <Box w="100%" pt="75px" h="75vh">
          <Text m="0 auto" w="95%" textAlign="center" fontSize="28px">
            Your communications preferences have been updated. Thank you.
          </Text>
        </Box>
      </Container>
    );
  }

  return (
    <Formik
      validationSchema={optOutValidationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ errors, setFieldValue, isSubmitting, values }) => (
        <Form>
          <Container>
            <Box textAlign="left" mb="80px">
              <TitleContainer>
                <Title>Customize Your Communications Preferences</Title>
              </TitleContainer>
              <Flex
                justifyContent="center"
                alignItems="center"
                gap="8px"
                flexDir="column"
                border="1px solid"
                borderColor="grayBackground"
                width={{ base: '95%', md: '75%', lg: '50%', xl: '650px' }}
                m="0 auto"
                p={{ base: '24px 18px', md: '18px 36px' }}
                borderRadius="15px"
                boxShadow="md"
              >
                <Subtitle>Opt Out</Subtitle>
                <Box textAlign="left" ml="2px" w="100%">
                  Opt Out Reason
                </Box>
                <Select name="opt_out_reason" options={optOutReasons} />
                <Input
                  display={{ base: 'none', md: 'block' }}
                  name="note"
                  placeholder="Note (optional)"
                  _input={{
                    maxLength: 60,
                  }}
                />
                <Textarea
                  display={{ base: 'block', md: 'none' }}
                  size="lg"
                  resize="vertical"
                  borderColor="grayDarkBackground"
                  name="note"
                  placeholder="Note (optional)"
                  maxLength={60}
                  fontSize="16px"
                  _focus={{
                    color: 'oceanBoatBlue',
                  }}
                  onChange={(e) => setFieldValue('note', e.target.value)}
                />
                <Collapse in={!!errors.note} animateOpacity>
                  <Text color="leaseEndRed" fontSize="12.25px" ml="5px">
                    {errors.note}
                  </Text>
                </Collapse>
                <Flex flexDir="column" w="100%" gap="8px">
                  <Checkbox
                    w="100%"
                    name="email_opted_out"
                    label="Email Opt Out"
                    checked={values.email_opted_out}
                    onChange={() => setFieldValue('email_opted_out', !values.email_opted_out)}
                  />
                  <Checkbox
                    w="100%"
                    name="sms_opted_out"
                    label="SMS/Call Opt Out"
                    checked={values.sms_opted_out}
                    onChange={() => setFieldValue('sms_opted_out', !values.sms_opted_out)}
                  />
                  <Checkbox
                    w="100%"
                    name="mail_opted_out"
                    label="Mail Opt Out"
                    checked={values.mail_opted_out}
                    onChange={() => setFieldValue('mail_opted_out', !values.mail_opted_out)}
                  />
                  <Collapse in={!!errors.opted_out_selection} animateOpacity>
                    <Text color="leaseEndRed" fontSize="12.25px" ml="5px">
                      You must opt out of at least one communication method
                    </Text>
                  </Collapse>
                  <Box
                    display={
                      values.email_opted_out || values.sms_opted_out || values.mail_opted_out
                        ? 'block'
                        : 'none'
                    }
                  >
                    <Text mb="8px">When would you like to opt back in to communications?</Text>
                    <Select name="opt_in_at" options={optOutTerms} />
                  </Box>
                </Flex>
              </Flex>
              <Flex
                flexDir="column"
                gap="8px"
                border="1px solid"
                borderColor="grayBackground"
                width={{ base: '95%', md: '75%', lg: '50%', xl: '650px' }}
                m="0 auto"
                mt="12px"
                p={{ base: '24px 18px', md: '18px 36px' }}
                borderRadius="15px"
                boxShadow="xl"
              >
                <Subtitle>Opt-Out Information</Subtitle>
                <Flex flexDir="column">
                  <Flex gap="8px" mb="8px">
                    <Input name="first_name" placeholder="First Name" />
                    <Input name="last_name" placeholder="Last Name" />
                  </Flex>
                  <Flex gap="8px" w="100%">
                    <Box w="50%">
                      <Collapse in={values.email_opted_out} animateOpacity>
                        <Input mb="8px" name="email" placeholder="Email" />
                      </Collapse>
                    </Box>
                    <Box w="50%">
                      <Collapse in={values.sms_opted_out} animateOpacity>
                        <Input
                          mb="8px"
                          name="phone_number"
                          placeholder="Phone Number"
                          mask="(999) 999-9999"
                          type="tel"
                        />
                      </Collapse>
                    </Box>
                  </Flex>
                  <Box display={values.mail_opted_out ? 'block' : 'none'}>
                    <Input name="address_line" placeholder="Address" />
                    <Input my="8px" name="address_line_2" placeholder="Address Line 2" />
                    <Flex gap="8px">
                      <Input name="city" placeholder="City" />
                      <Select name="state" placeholder="State" options={statesArray} />
                      <Input
                        name="zip"
                        placeholder="Zip"
                        _input={{
                          inputMode: 'numeric',
                          maxLength: 5,
                        }}
                      />
                    </Flex>
                  </Box>
                  <VStack w="100%" textAlign="center" mt="15px">
                    <ReCaptcha
                      isVisible
                      onExpired={() => setReCaptchaVerified(false)}
                      onVerified={() => setReCaptchaVerified(true)}
                    />
                    <PrimaryButton
                      type="submit"
                      isDisabled={!reCaptchaVerified && recaptchaEnabled}
                      isLoading={isSubmitting}
                    >
                      SUBMIT PREFERENCES
                    </PrimaryButton>
                  </VStack>
                </Flex>
              </Flex>
            </Box>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default OptOut;
