import { useCallback } from 'react';

import { useEventListener } from '../hooks';
import { CookieKeys, clearCookie } from '../hooks/useCookie';
import { RequireAtLeastOne } from '../utils/types/typeHelpers';

export enum LocalStorageKeys {
  ACQUISITION_VEHICLE_IMAGES = 'acquisition_vehicle_images',
  ONE_CLICK_LOGIN_EMAIL = 'one_click_login_email',
  INACTIVITY_LOGOUT = 'inactivity_logout',
  LINK_ACCOUNT_INVALID_SSN = 'link_account_invalid_ssn',
  REFLECT_FLAGS = 'reflect_flags',
  LAST_ACTIVITY = 'last_activity',
  DEV_TEST_EXPIRATION = 'dev_test_expiration',
}

export type ResetLocalStorageOpts = RequireAtLeastOne<{
  removeGuid?: boolean;
  removeAccessToken?: boolean;
  removeAcquisitionVehicleImages?: boolean;
}>;

export const triggerStorageEvent = (key: LocalStorageKeys) => {
  window.dispatchEvent(new StorageEvent('storage', { key }));
};

export const resetLocalStorage = ({
  removeGuid,
  removeAccessToken,
  removeAcquisitionVehicleImages,
}: ResetLocalStorageOpts) => {
  if (removeGuid) {
    clearCookie(CookieKeys.GUID_KEY);
  }

  if (removeAccessToken) {
    clearCookie(CookieKeys.ACCESS_TOKEN);
  }

  if (removeAcquisitionVehicleImages) {
    localStorage.removeItem(LocalStorageKeys.ACQUISITION_VEHICLE_IMAGES);
    triggerStorageEvent(LocalStorageKeys.ACQUISITION_VEHICLE_IMAGES);
  }

  localStorage.removeItem(LocalStorageKeys.ONE_CLICK_LOGIN_EMAIL);
  triggerStorageEvent(LocalStorageKeys.ONE_CLICK_LOGIN_EMAIL);
};

export const getLocalStorageItem = <T>(key: LocalStorageKeys): T | null => {
  const value = window.localStorage.getItem(key);

  try {
    return JSON.parse(value || '') as T;
  } catch {
    return null;
  }
};

export const setLocalStorageItem = <T>(key: LocalStorageKeys, value: T) => {
  window.localStorage.setItem(key, JSON.stringify(value));
  triggerStorageEvent(key);
};

export const getLocalStorageItemOrInitial = <T>(key: LocalStorageKeys, initial: T): T => {
  const value = getLocalStorageItem<T>(key);

  if (value != null) {
    return value;
  }

  setLocalStorageItem(key, initial);

  return initial;
};

export const useLocalStorageEvent = (
  key: LocalStorageKeys,
  callback: (event: StorageEvent) => void,
) => {
  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (!event?.key || event.key !== key) {
        return;
      }
      callback(event);
    },
    [key, callback],
  );

  useEventListener('storage', handleStorageChange);
};
