import { Icon, IconProps, Link } from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface SocialMediaIconLinkProps extends IconProps {
  icon: IconType;
  href: string;
  ariaLabel?: string;
}

const SocialMediaIconLink = ({ icon, href, ariaLabel, ...rest }: SocialMediaIconLinkProps) => {
  return (
    <Link href={href} aria-label={ariaLabel} isExternal>
      <Icon boxSize={10} color="midnightBlue" as={icon} {...rest} />
    </Link>
  );
};

export default SocialMediaIconLink;
