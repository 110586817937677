import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  inputAnatomy.keys,
);

const variants = {
  outline: definePartsStyle({
    field: {
      color: 'charcoal',
      border: '1px solid',
      borderColor: 'grayDarkBackground',
      fontWeight: 'semibold',
      borderRadius: '3px',
      padding: '10px',
      bg: 'white',
      fontFamily: 'body',
      _readOnly: {
        userSelect: 'all',
        bg: '#f8f8f8',
        color: 'gray.600',
        border: 'solid 1px',
        borderColor: 'readOnlyGray',
      },
      _invalid: {
        bg: 'leaseEndRedBG',
        color: 'leaseEndRed',
        borderColor: 'rgba(188,83,83,0.25)',
        boxShadow: 'none',
        _focusVisible: {
          color: 'leaseEndRed',
          borderColor: 'rgba(188,83,83,1)',
        },
      },
      _focusVisible: {
        color: 'oceanBoatBlue',
        borderColor: 'oceanBoatBlue',
        boxShadow: 'none',
      },
      _disabled: {
        opacity: 1,
        bg: 'grayDisabledBackground',
        color: 'taupeGray',
      },
    },
  }),
};

const md = {
  fontSize: '16px',
};
const lg = {
  fontSize: '18px',
  h: '50px',
};

const sizes = {
  md: definePartsStyle({
    field: md,
    addon: md,
  }),
  lg: definePartsStyle({
    field: lg,
    addon: lg,
  }),
};

const inputTheme = defineMultiStyleConfig({
  sizes,
  variants,
});

export default inputTheme;
