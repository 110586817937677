import { Flex, FlexProps } from '@chakra-ui/react';
import React from 'react';

const TitleContainer: React.FC<FlexProps> = ({ children, ...props }) => (
  <Flex justifyContent="center" mb={{ base: '20px' }} mt="10px" {...props}>
    {children}
  </Flex>
);

export default TitleContainer;
