/* eslint-disable jsx-a11y/media-has-caption */
import { Box, Icon, Image, useBreakpointValue } from '@chakra-ui/react';
import { MdOutlinePlayCircle } from 'react-icons/md';

import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import { Action } from './VideoReviewCarousel';

interface Props {
  name: string;
  isCurrent: boolean;
  handleAction: (action: Action) => void;
  isNextVideo: boolean;
}

const VideoThumbnail = ({ name, handleAction, isCurrent, isNextVideo }: Props) => {
  const thumbnail = `/video-reviews/thumbnails/${name}.jpg`;
  const video = `/video-reviews/videos/${name}.mp4`;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleClick = () => {
    // Clicking on middle thumbnail
    if (isCurrent) {
      rudderanalytics.track(RudderEvent.VideoReviewStarted, {
        video: `${name}.mp4`,
      });
      return;
    }
    // Clicking on right thumbnail
    if (isNextVideo) {
      handleAction('next');
      return;
    }
    // Clicking on left thumbnail
    handleAction('prev');
  };

  return (
    <Box position="relative" onClick={handleClick}>
      {isCurrent ? (
        <video
          controls
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          style={{
            border: '1px solid white',
            borderRadius: '5px',
            outline: isCurrent ? '1px solid #015FCC' : '', // trueBlue color - video component requires hex code
            background: 'white',
            height: isMobile ? 'auto' : '436.58px',
          }}
        >
          <source src={video} type="video/mp4" />
        </video>
      ) : (
        <>
          <Image
            src={thumbnail}
            style={{ filter: `brightness(${isCurrent ? 1 : 0.6})` }}
            zIndex={1}
            cursor="pointer"
            boxShadow={isCurrent ? 'xl' : 'none'}
            borderRadius="8px"
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex={2}
          >
            <Icon
              as={MdOutlinePlayCircle}
              color="white"
              opacity={0.75}
              display={isCurrent ? 'block' : 'none'}
              _hover={{ opacity: isCurrent ? 0.8 : 0.5 }}
              cursor={isCurrent ? 'pointer' : 'default'}
              fontSize="52px"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default VideoThumbnail;
