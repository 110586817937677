import { Box, HStack } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  currStep: number;
  setIndex: Dispatch<SetStateAction<number>>;
  totalSteps: number;
}

const ItemDot = ({ isCurrent, onClick }: { isCurrent: boolean; onClick: () => void }) => (
  <Box
    borderRadius="50%"
    bgColor={isCurrent ? 'leaseEndYellow' : 'leaseEndSkyBlue'}
    boxSize="8px"
    cursor="pointer"
    onClick={onClick}
  />
);

const CarouselProgressBar = ({ currStep, setIndex, totalSteps }: Props) => {
  return (
    <HStack borderRadius="25px" px={5} bgColor="gainsboro" h="15px" w="min">
      {[...Array(totalSteps)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ItemDot key={index} isCurrent={index === currStep} onClick={() => setIndex(index)} />
      ))}
    </HStack>
  );
};

export default CarouselProgressBar;
