import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';

import { MAIN_NUMBER } from '../constants/contact';
import { HOME } from '../constants/urls';

// This is for the app's Error Boundry. This is what we want to show when our app has a fatal error
const ErrorPage: React.ComponentProps<typeof Sentry.ErrorBoundary>['fallback'] = () => {
  const handleNoCacheRefresh = () => {
    window.localStorage.clear();
    // https://stackoverflow.com/questions/10719505/force-a-reload-of-page-in-chrome-using-javascript-no-cache
    const oAjax = new XMLHttpRequest();
    oAjax.open('get', HOME);
    oAjax.setRequestHeader('Pragma', 'no-cache');
    oAjax.send();
    oAjax.onreadystatechange = () => {
      if (oAjax.readyState === 4) {
        window.location.reload();
        window.location.href = HOME;
      }
    };
  };

  return (
    <Flex alignItems="center" justifyContent="center" minH="100vh" minW="100vw" p={10}>
      <Box maxW="600px" textAlign="center">
        <Text>
          We encountered an unexpected error. Please refresh the page using the button below and try
          again. If the issue persists, give us a call at{' '}
          <Link href={`tel:+1-${MAIN_NUMBER}`}>{MAIN_NUMBER}</Link>.
        </Text>
        <Button onClick={handleNoCacheRefresh}>REFRESH</Button>
      </Box>
    </Flex>
  );
};

export default ErrorPage;
