import { WebAuth } from 'auth0-js';

import config from '../config';
import { HOME } from '../constants/urls';
import { resetLocalStorage } from './localStorage';

export enum AuthType {
  Email = 'email',
  PhoneNumber = 'sms',
  Password = 'Username-Password-Authentication',
}

const getExternalAuthClient = (): WebAuth => {
  return new WebAuth({
    clientID: config.auth0.externalClientId,
    domain: config.auth0.externalDomain,
    audience: config.auth0.audience,
    responseType: 'token',
  });
};

export interface LogoutOpts {
  queryParams?: URLSearchParams;
}
export const logout = ({ queryParams }: LogoutOpts = {}) => {
  resetLocalStorage({ removeGuid: true, removeAccessToken: true });
  const queryParamsString = queryParams?.toString();
  getExternalAuthClient().logout({
    returnTo: `${window.location.origin}${HOME}${queryParamsString ? `?${queryParamsString}` : ''}`,
  });
};
