import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import React, { forwardRef } from 'react';

import { HomePageSimpleBackgroundWideSVG, RefiCar } from '../../../assets/Images';
import useCustomBreakpointCallback from '../../../hooks/useCustomBreakpointCallback';

const RefiBgImage = forwardRef<HTMLImageElement>((_: unknown, ref) => {
  const bpv = useCustomBreakpointCallback();
  const [heightUnder1050] = useMediaQuery('(max-height: 1050px)');

  return (
    <Box position="absolute" h="100vh" zIndex="-2" w="100%" top={0}>
      <Image
        src={RefiCar}
        alt="Car Dude"
        position="absolute"
        top={{
          base: bpv({ 500: '46%', 625: '40%' }),
          md: heightUnder1050 ? '42.5%' : '500px',
        }}
        left={{ base: '0', md: '50%' }}
        transform="translate(-50%, 0)"
        w={{
          md: bpv(
            {
              500: '300px',
              600: '325px',
              700: '400px',
              800: '500px',
              900: bpv({ 768: '500px', 800: '550px', 900: '650px' }),
            },
            true,
          ),
        }}
        minW={{ base: '550px', md: '0px' }}
        transition="opacity 0.5s ease-in-out"
        overflow="hidden"
        m="0 auto"
      />
      <Image
        ref={ref}
        src={HomePageSimpleBackgroundWideSVG}
        alt="Scenic Background"
        h="100%"
        minH="750px"
        maxH="1080px"
        objectFit="cover"
        objectPosition={{ base: '64% -35px', md: 'center -35px' }}
        zIndex="-1"
        m="0 auto"
      />
    </Box>
  );
});

export default RefiBgImage;
