/* eslint-disable no-underscore-dangle */

/* eslint-disable no-unused-vars */
import { Box, Container, Flex, Link } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import '../../assets/sanity.css';
// import ModalWiki from '../../components/Modal/ModalWiki';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Loading from '../Loading';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { postFetch } from '../Sanity/Data';
import { BlogPostProps, ptBlogComponents } from '../Sanity/Model';

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [post, setPost] = useState<BlogPostProps | null>(null);

  const fetch = async () => {
    setLoading(true);
    const data: BlogPostProps | null = await postFetch(slug);
    setLoading(false);
    setPost(data);
  };

  const seo = () => {
    if (!document.querySelector('meta[name="description"]')) {
      const desc = document.createElement('meta');
      desc.name = 'description';
      desc.httpEquiv = 'X-UA-Compatible';
      desc.content = post?.description || '';
      document.getElementsByTagName('head')[0].appendChild(desc);
    } else {
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', post?.description || '');
    }
    if (!document.querySelector('meta[name="keywords"]')) {
      const desc = document.createElement('meta');
      desc.name = 'keywords';
      desc.httpEquiv = 'X-UA-Compatible';
      desc.content = post?.keywords || '';
      document.getElementsByTagName('head')[0].appendChild(desc);
    } else {
      document
        .querySelector('meta[name="keywords"]')
        ?.setAttribute('content', post?.keywords || '');
    }
    document.title = post?.browserTitle || document.title;
  };

  useEffect(() => {
    const handleUrlChange = () => {
      const id = decodeURI(window.location.hash.substring(1));
      document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
    };
    window.addEventListener('hashchange', handleUrlChange);
    return () => {
      window.removeEventListener('hashchange', handleUrlChange);
    };
  }, []);

  useEffect(() => {
    if (post) {
      seo();
    }
  }, [post]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" px={{ md: 16 }}>
        {loading ? (
          <Loading />
        ) : post ? (
          <>
            <Flex
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              maxWidth={760}
              mx="auto"
            >
              {/* Post title */}
              <TitleContainer alignContent="center" justifyContent="flex-start" flexDirection="row">
                <Title
                  textAlign="left"
                  fontSize={{ base: '28px', md: '42px' }}
                  id="leTop"
                  fontWeight={700}
                >
                  {post.title || 'no title'}
                </Title>
              </TitleContainer>
              {/* Author */}
              <Flex
                alignItems="center"
                gap={2}
                flexDir="row"
                borderBottomColor="#ddd"
                borderBottomWidth={1}
                flex={1}
                w="100%"
                pb={4}
                mb={4}
              >
                {post.authorImage && <BlogImageComponent value={post.authorImage} isInline />}
                <Flex flexDir="column" alignItems="flex-start" gap={0}>
                  <Link
                    className="le-blog-author"
                    href={`/author/${post.authorSlug}?ref=${location.pathname}`}
                  >
                    {post.authorName}
                  </Link>
                  <Box className="le-blog-date">
                    {moment(new Date(post.publishDate)).format('M/D/YY h:mma')}
                  </Box>
                </Flex>
              </Flex>
              {/* Publish date */}
              {/* Post body */}
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                alignContent="flex-start"
                textAlign="left"
                className="le-blog-post"
                flex={1}
                w="100%"
              >
                <PortableText value={post.body} components={ptBlogComponents} />
              </Flex>
            </Flex>
          </>
        ) : (
          <Box>Sorry, we couldn't load this post</Box>
        )}
      </Container>
      {/* <ModalWiki /> */}
    </>
  );
};

export default BlogPost;
