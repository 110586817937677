import { Box, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import { useTotalCompletedDealsQuery } from '../../../gql/generated/graphql';

const Odometer = ({ ...props }: FlexProps) => {
  const [numArr, setNumArr] = useState<string[][]>([[]]);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [odometer, setOdometer] = useState<HTMLDivElement | null>(null);
  const observeElement = useCallback((node: HTMLDivElement | null) => {
    setOdometer(node);
  }, []);

  const completedDeals = useTotalCompletedDealsQuery();

  useEffect(() => {
    // Create intersection observer to check if odometer is in view.
    if (!odometer) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          setIsVisible(true);
        }
      },
      { threshold: 1.0 },
    );
    observer.observe(odometer);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [odometer]);

  useEffect(() => {
    // Wait until completed deals has loaded.
    if (completedDeals.data?.totalCompletedDeals && isVisible) {
      const finalNumsArr = completedDeals.data?.totalCompletedDeals
        .toString()
        .padStart(6, '0')
        .split('');

      const newNumArr: string[][] = [];

      for (let i = 0; i < 6; i++) {
        const tempArr = [];
        const tempNum = Number(finalNumsArr[i]);
        for (let j = 0; j < 20; j++) {
          tempArr.push(String((tempNum + j) % 10));
        }
        newNumArr.push(tempArr.reverse());
      }

      setNumArr(newNumArr);
    }
  }, [completedDeals.data?.totalCompletedDeals, isVisible]);

  return completedDeals.data?.totalCompletedDeals ? (
    <Flex
      ref={observeElement}
      mx="10px"
      border="solid 1px white"
      outline="solid 1px gray"
      zIndex="1"
      h={{ base: '42px', lg: '47px' }}
      w="194px"
      sx={{
        '@keyframes slideUp': {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-95%)' },
        },
        '.slideOutLinear': {
          animation: `slideUp ease-in-out`,
          animationFillMode: 'forwards',
        },
      }}
      {...props}
    >
      {isVisible &&
        numArr.map((arr, i) => {
          return (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              color="white"
              fontSize={{ sm: '2xl', md: '3xl', lg: '4xl' }}
              fontWeight="bold"
              background="linear-gradient(black, #222 10%, #333 20%, #333 80%, #222 90%, black)"
              border="solid 1px black"
              pl="5px"
              pr="5px"
              overflow="hidden"
              h={{ md: '40px', lg: '45px' }}
            >
              <Flex
                className="num-slot slideOutLinear"
                flexDir="column"
                w="20px"
                textAlign="center"
                style={{ animationDuration: `${1 + i * 0.2}s` }}
              >
                {arr.map((num, j) => {
                  return (
                    <Text
                      m="auto"
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={j}
                    >
                      {num}
                    </Text>
                  );
                })}
              </Flex>
            </Box>
          );
        })}
    </Flex>
  ) : (
    <Heading p="0 10px" color="leaseEndYellow" fontSize={{ md: '4xl', lg: '5xl' }}>
      10,000+
    </Heading>
  );
};

export default Odometer;
