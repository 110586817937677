import {
  Flex,
  FlexProps,
  Icon,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { IconType } from '@react-icons/all-files/lib';
import { useField } from 'formik';

import { formatMoney } from '../utils/helpers';
import Input from './formComponents/Input';
import NumberInput from './formComponents/NumberInput';

interface Props extends FlexProps {
  icon: IconType;
  name: string;
  max: number;
  min: number;
  title?: string;
  subtitle?: string;
  label?: string;
  inputMax?: number;
  isMoney?: boolean;
  value?: number;
  step?: number;
  minMark?: string;
  maxMark?: string;
  readOnly?: boolean;
  inputValue?: string;
  customOnChange?: (v: number) => unknown;
  subtitleProps?: TextProps;
}

const SliderOption = ({
  icon,
  name,
  max,
  min,
  title,
  subtitle,
  label,
  inputMax,
  isMoney = false,
  value,
  minMark = '',
  maxMark = '',
  readOnly = false,
  inputValue,
  step = 1,
  customOnChange,
  subtitleProps = {},
  w,
}: Props) => {
  const [field, , { setValue }] = useField(name);

  return (
    <Flex
      flexDir="column"
      p={3}
      w={w || { base: '200px', md: '24%', lg: '20%' }}
      minW={{ base: 'unset', md: '140px' }}
    >
      {title && (
        <Text color="leaseEndBlue" fontSize={18} whiteSpace="nowrap">
          {title}
        </Text>
      )}
      {subtitle && (
        <Text fontSize={12} {...subtitleProps} whiteSpace="nowrap">
          {subtitle}
        </Text>
      )}
      {inputValue ? (
        <Input
          label={label}
          value={inputValue}
          placeholder=""
          name={name}
          icon={<Icon as={icon} w={5} h={5} color="leaseEndGreen" mr={6} />}
          readOnly={readOnly}
        />
      ) : (
        <NumberInput
          label={label}
          placeholder=""
          name={name}
          icon={<Icon as={icon} w={5} h={5} color="leaseEndGreen" mr={6} />}
          readOnly={readOnly}
          min={min}
          max={inputMax ?? max}
          isMoney={isMoney}
        />
      )}
      <Slider
        min={min}
        max={max}
        value={value || field.value}
        name={name}
        focusThumbOnChange={false}
        step={step}
        onChange={customOnChange || ((val: string | number) => setValue(val))}
      >
        <SliderMark value={min} mt={4} fontSize="sm" whiteSpace="nowrap">
          {minMark || (isMoney ? formatMoney(min, { noCents: true }) : min)}
        </SliderMark>
        <SliderMark value={max} mt={4} fontSize="sm" ml="-40px" whiteSpace="nowrap">
          {maxMark || (isMoney ? formatMoney(max, { noCents: true }) : max)}
        </SliderMark>
        <SliderTrack mt={2} bg="white" boxShadow="0px 3px 6px #00000029" h="8px">
          <SliderFilledTrack bg="leaseEndGreen" />
        </SliderTrack>
        <SliderThumb mt={2} boxSize={5} borderColor="leaseEndGreen" borderWidth={4} />
      </Slider>
    </Flex>
  );
};

export default SliderOption;
