import { Button, CircularProgress, CircularProgressLabel, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface Props {
  message: string;
  onCancel: () => void;
  onPlayNext: () => void;
  seconds: number;
}

const CircularCountdown = ({ message, seconds, onCancel, onPlayNext }: Props) => {
  const [progress, setProgress] = useState(seconds);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let interval: NodeJS.Timeout;
    if (progress > 0) {
      interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress - 1);
      }, 1000);
    } else {
      onPlayNext();
    }

    return () => clearInterval(interval);
  }, [progress, seconds]);

  return (
    <VStack>
      <Text color="leaseEndBlueDark" fontSize="22" fontWeight="bold" textAlign="center">
        {message}
      </Text>
      <CircularProgress size={28} isIndeterminate color="oceanBoatBlue">
        <CircularProgressLabel mt="-6px">
          <Text color="leaseEndBlueDark" fontSize="28" fontStyle="bold" textAlign="center">
            {progress}
          </Text>
        </CircularProgressLabel>
      </CircularProgress>
      <Button variant="secondary" size="md" onClick={onCancel}>
        Cancel
      </Button>
    </VStack>
  );
};

export default CircularCountdown;
