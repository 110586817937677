import {
  BAD_RATE,
  BEST_RATE,
  DEFAULT_INITIAL_SCORE,
  GOOD_RATE,
  WORST_RATE,
} from '../constants/paymentCalculator';
import { CreditScore } from '../gql/generated/graphql';

export const creditScores = [
  { name: CreditScore.BelowAverage, min: 300, max: 599, rate: WORST_RATE },
  { name: CreditScore.BelowAverage, min: 600, max: 649, rate: WORST_RATE },
  { name: CreditScore.Fair, min: 650, max: 699, rate: BAD_RATE },
  { name: CreditScore.Good, min: 700, max: 749, rate: GOOD_RATE },
  { name: CreditScore.Great, min: 750, max: 799, rate: BEST_RATE },
  { name: CreditScore.Great, min: 800, max: 850, rate: BEST_RATE },
];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DEFAULT_SCORE = creditScores.find(
  (score) => score.max > DEFAULT_INITIAL_SCORE && score.min <= DEFAULT_INITIAL_SCORE,
)!;

export const DEFAULT_RATE = DEFAULT_SCORE?.rate;

export const getCreditScore = (score: number) => {
  const rate = creditScores.find(({ min, max }) => score >= min && score <= max);
  return rate || DEFAULT_SCORE;
};

export const getCreditScoreRangeText = (credit_score: number) => {
  const score = getCreditScore(credit_score);

  if (score.min === 800) {
    return '800+';
  }

  return `${score.min}-${score.max}`;
};

export const getCreditScoreGrade = (credit_score: number) => {
  const score = getCreditScore(credit_score);

  switch (score.rate) {
    case WORST_RATE:
      return 'Below Average';
    case BAD_RATE:
      return 'Fair';
    case GOOD_RATE:
      return 'Good';
    case BEST_RATE:
      return 'Great';
    default:
      return 'Good';
  }
};

export const termLengths = [
  { label: '48 MONTHS', key: '1', value: 48 },
  { label: '60 MONTHS', key: '2', value: 60 },
  { label: '72 MONTHS', key: '3', value: 72 },
  { label: '84 MONTHS', key: '4', value: 84 },
];

export const termsArr = termLengths.map((tl) => tl.value);
