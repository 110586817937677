import { Box, Flex, Heading } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { LEAssetFolderProps } from '../Sanity/Model';

const Folder = (props: { folder: LEAssetFolderProps }) => {
  const location = useLocation();
  const { folder } = props;
  const go = (name: string) => {
    window.location.href = `${location.pathname}?folder=${name}`;
  };
  return (
    <>
      <Box border="1px solid #ddd" mt={8} ps={8} pe={8} pb={2} borderRadius={16}>
        <Flex justifyContent="flex-start" direction="row" alignItems="center">
          <Heading
            pt={6}
            pb={4}
            mt={0}
            as="h2"
            size="lg"
            fontWeight={600}
            lineHeight={1.3}
            color="leaseEndBlueDark"
            onClick={() => go(folder.title)}
          >
            {folder.title}
          </Heading>
        </Flex>
      </Box>
    </>
  );
};

export default Folder;
