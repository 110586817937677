import { gql } from '@apollo/client';

export const getTTJurisdiction = gql`
  query getTTJurisdiction(
    $state: StateAbbreviation!
    $types: [TTProductType!]!
    $conditions: TTConditionFilterInput
  ) {
    jurisdiction(state: $state) {
      id
      hasRegistrationOnly
      hasTitleOnly
      hasTitleAndRegistration
      allowPlateTransfer
      requireWalkIn
      products(filter: { types: $types }) {
        count
        pageInfo {
          startCursor
          endCursor
        }
        items {
          type
          validations {
            name
            description
          }
          documents(conditions: $conditions) {
            count
            items {
              supportsImageExtraction
              productId
              documentTypeId
              type {
                name
                parameters {
                  name
                  description
                  type
                }
              }
              conditions {
                description
                variables
              }
              validations {
                name
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const uploadDocument = gql`
  mutation uploadDocument($bytes: String!, $documentType: TTDocumentName!) {
    uploadDocument(bytes: $bytes, documentType: $documentType) {
      documentName
      key
      fields {
        confidence
        name
        value
      }
    }
  }
`;
