import React from 'react';
import { Text, Flex, Link } from '@chakra-ui/react';
import Modal from '../../../components/Modal';
import PrimaryButton from '../../../components/ui/buttons/PrimaryButton';
import { useSearchPhoneNumbers } from '../../../hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ExpiredPayoffRequestModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const phoneNumber = useSearchPhoneNumbers();

  return (
    <Modal
      title="Your Request Has Expired"
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount={false}
      isCentered
      closeOnOverlayClick={false}
    >
      <Flex flexDir="column">
        <Text color="leaseEndBlue" mb={6} textAlign="center" fontSize={{ base: 'lg', md: 'xl' }}>
          We have your payoff, but your request to continue your application online has expired.
          However, don't worry; we're here to assist you with the next steps. If you haven't heard
          from us, please give us a call at{' '}
          <Link href={`tel:+1-${phoneNumber}`}>{phoneNumber}</Link>.
        </Text>
        <PrimaryButton w="fit-content" alignSelf="center" onClick={onClose}>
          OK
        </PrimaryButton>
      </Flex>
    </Modal>
  );
};

export default ExpiredPayoffRequestModal;
