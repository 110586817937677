import { Link, Text } from '@chakra-ui/react';
import React from 'react';

import Modal from '../../../components/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const HomeBannerModal: React.FC<Props> = ({ isOpen, onClose }) => (
  <Modal title="Terms & Conditions" isOpen={isOpen} onClose={onClose}>
    <Text>
      To be eligible to win customers must sign their lease buyout contracts by July 6th, 2024.
      Customers who sign their contracts by July 6th will receive an email from
      <Link href="https://www.tangocard.com/" isExternal ps={1}>
        Tango Card
      </Link>{' '}
      with a link to their gift card.
    </Text>
  </Modal>
);

export default HomeBannerModal;
