import React from 'react';
import { Box } from '@chakra-ui/react';
import Modal from '../../Modal';
import PrimaryButton from '../buttons/PrimaryButton';

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  handleClick: () => void;
}

const StartOverModal: React.FC<Props> = ({ open, setOpen, handleClick }) => {
  return (
    <Modal
      title="Are you sure you'd like to start over?"
      showCloseButton
      onClose={() => setOpen(false)}
      isOpen={open}
      size="3xl"
    >
      <Box textAlign="center">
        <PrimaryButton onClick={handleClick}>YES, I'LL START OVER</PrimaryButton>
      </Box>
    </Modal>
  );
};

export default StartOverModal;
