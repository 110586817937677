import { Box, Heading } from '@chakra-ui/react';

import CustomerReviewsSwiper from '../../../components/ui/reviews/CustomerReviewsSwiper';
import VideoReviewCarousel from '../../../components/ui/reviews/VideoCarousel/VideoReviewCarousel';
import { LDFlags } from '../../../constants/experiments';
import { useFlag } from '../../../hooks';

const Reviews = () => {
  const showVideoReviewFlag = useFlag(LDFlags.SHOW_VIDEO_CAROUSEL);

  return (
    <Box
      mb={{
        base: showVideoReviewFlag ? '10px' : '20px',
        md: showVideoReviewFlag ? '25px' : '50px',
      }}
      p={{
        base: '0 25px',
        md: showVideoReviewFlag ? '100px' : '50px',
      }}
    >
      <Heading
        fontWeight="semibold"
        fontSize={{ base: 25, md: 35 }}
        textAlign="center"
        color="leaseEndBlue"
        mb={10}
      >
        {showVideoReviewFlag
          ? 'Find out why these drivers love Lease End.'
          : "Here's what some of them have to say:"}
      </Heading>
      {showVideoReviewFlag ? <VideoReviewCarousel /> : <CustomerReviewsSwiper />}
    </Box>
  );
};

export default Reviews;
