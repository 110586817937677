import { createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';

import { LDFlags } from '../../constants/experiments';
import { HOME, MONTHLY_PAYMENT_CALCULATOR, OPT_OUT } from '../../constants/urls';
import useFlag from '../../hooks/useFlag';
import './styles.css';

export const SHOW_RECAPTCHA_IN_PATHS = [OPT_OUT, MONTHLY_PAYMENT_CALCULATOR, HOME];
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
export const invisibleReCaptchaRef = createRef<ReCAPTCHA>();
export const checkboxReCaptchaRef = createRef<ReCAPTCHA>();

export const isReCaptchaEnabled = (isReCaptchaFlagEnabled: boolean) =>
  !window.isReflectTest && RECAPTCHA_SITE_KEY && isReCaptchaFlagEnabled;

interface Props {
  isVisible?: boolean;
  onExpired?: () => void;
  onVerified?: () => void;
}

const ReCaptcha = ({ isVisible, onExpired, onVerified }: Props) => {
  const { pathname } = useLocation();
  const isReCaptchaFlagEnabled = useFlag(LDFlags.RE_CAPTCHA_ENABLED);

  if (!isReCaptchaEnabled(isReCaptchaFlagEnabled)) {
    return null;
  }

  if (isVisible && onVerified && onExpired) {
    return (
      <ReCAPTCHA
        ref={checkboxReCaptchaRef}
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onVerified}
        onExpired={onExpired}
      />
    );
  }

  return (
    <ReCAPTCHA
      ref={invisibleReCaptchaRef}
      badge="bottomleft"
      size="invisible"
      sitekey={RECAPTCHA_SITE_KEY}
      style={SHOW_RECAPTCHA_IN_PATHS.includes(pathname) ? undefined : { display: 'none' }}
    />
  );
};

export default ReCaptcha;
