import React from 'react';
import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps,
} from '@chakra-ui/react';

interface Props extends FormErrorMessageProps {
  children: React.ReactNode;
}

const FormErrorMessage = ({ children, ...rest }: Props) => (
  <ChakraFormErrorMessage color="leaseEndRed" {...rest}>
    {children}
  </ChakraFormErrorMessage>
);

export default FormErrorMessage;
