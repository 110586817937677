import { Flex, FlexProps, Image, Text } from '@chakra-ui/react';

import { RefiLogo } from '../../../assets/Images';
import Title from '../../../components/Title';
import useCheckFlowStatus from '../../../hooks/useCheckFlowStatus';
import useCustomBreakpointCallback from '../../../hooks/useCustomBreakpointCallback';

const RefiHero = ({ ...props }: FlexProps) => {
  const { inFlow } = useCheckFlowStatus();
  const bpv = useCustomBreakpointCallback();

  return (
    <Flex
      flexDir="column"
      textAlign="center"
      alignItems="center"
      paddingBottom="20px"
      transform={{
        base: 'scale(0.9)',
        md: bpv(
          {
            600: 'scale(0.8)',
            800: inFlow ? 'scale(0.85)' : 'scale(0.9)',
            900: inFlow ? 'scale(0.9)' : 'scale(1)',
            1000: 'scale(1)',
          },
          true,
        ),
      }}
      mt={{
        base: inFlow ? '-120px' : '-30px',
        sm: bpv(
          {
            700: inFlow ? '-80px' : '-50px',
            800: inFlow ? '-40px' : '-30px',
            1000: '-50px',
          },
          true,
        ),
        md: bpv(
          {
            700: inFlow ? '-80px' : '-50px',
            800: inFlow ? '-40px' : '-30px',
            1000: '-50px',
          },
          true,
        ),
      }}
      {...props}
    >
      <Title
        pt={bpv(
          {
            0: '10px',
            875: '45px',
            960: '75px',
            1050: '125px',
          },
          true,
        )}
        size={{ base: '2xl', lg: '3xl' }}
        color="black"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        display="flex"
        flexDir="column"
      >
        <Image src={RefiLogo} w={300} mb={4} />
        Refinance Your Car With Us Today
      </Title>
      <Text fontSize={{ base: 'xl', lg: '2xl' }} mb="25px" display={{ base: 'none', md: 'block' }}>
        We may be called Lease End but we are also great at refinancing auto loans
      </Text>

      <Flex>
        <Title
          fontSize={{
            base: '3rem',
            md: '4rem',
          }}
          fontWeight={700}
        >
          (833) 999-1584
        </Title>
      </Flex>
    </Flex>
  );
};

export default RefiHero;
