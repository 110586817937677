import { string } from 'yup';

export const emailValidationNoAlias = string()
  .nullable()
  .required('Email is required')
  .email('Please enter a valid email')
  .test('no-alias', 'You may not use email aliases', (value) => {
    if (!value) return false;
    if (value.includes('+')) {
      return false;
    }
    return true;
  });

export const emailValidation = string()
  .nullable()
  .required('Email is required')
  .email('Please enter a valid email');
