import { useEffect, useRef, useState } from 'react';

type SizeType = 'client' | 'offset' | 'scroll';

interface ElementSize {
  width: number;
  height: number;
}

const getSize = (type: SizeType, element: HTMLElement | null) => {
  if (!element) {
    return {
      width: 0,
      height: 0,
    };
  }
  switch (type) {
    case 'client':
      return {
        width: element.clientWidth,
        height: element.clientHeight,
      };
    case 'offset':
      return {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
    case 'scroll':
      return {
        width: element.scrollWidth,
        height: element.scrollHeight,
      };
    default:
      return {
        width: 0,
        height: 0,
      };
  }
};

const useRefSize = <T extends HTMLElement = HTMLDivElement>(type: SizeType = 'client') => {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    const handleSize = () => {
      const { width, height } = getSize(type, ref.current);
      setSize({ width, height });
    };
    handleSize();
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);
  return [ref, size] as const;
};

export default useRefSize;
