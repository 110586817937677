import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { rudderanalytics } from '../../utils/rudderstack';

export interface LERouteProps extends RouteProps {
  title: string;
  description?: string;
  keywords?: string;
  children?: React.ReactNode;
}

const LERoute = ({ children, title, description, keywords, ...rest }: LERouteProps) => {
  useEffect(() => {
    document.title = title || '';

    ['description', 'keywords'].forEach((name) => {
      const content = name === 'description' ? description || '' : keywords || '';

      if (!document.querySelector(`meta[name="${name}"]`)) {
        const meta = document.createElement('meta');
        meta.name = name;
        meta.httpEquiv = 'X-UA-Compatible';
        meta.content = content;
        document.getElementsByTagName('head')[0].appendChild(meta);
      } else {
        document?.querySelector(`meta[name="${name}"]`)?.setAttribute('content', content);
      }
    });
  }, [title, description, keywords]);

  useEffect(() => {
    rudderanalytics.page(title);
  }, [title]);

  return <Route {...rest}>{children}</Route>;
};

export default LERoute;
