import { Box, Button, Divider, Flex, Icon, Input, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { MdUploadFile } from 'react-icons/md';

interface LeasePeaceContactInfoProps {
  files: File[];
}

const LeasePeaceContactInfo: FC<LeasePeaceContactInfoProps> = ({ files }) => {
  return (
    <Box w={{ base: '100%' }} maxW={{ lg: '50%', xl: '600px' }}>
      <Box textAlign={{ base: 'center', lg: 'left' }} mb="45px">
        <Text
          fontSize={{ base: '20px', lg: '22px', xl: '24px' }}
          lineHeight={{ base: '20px', lg: '22px', xl: '24px' }}
          fontWeight="bold"
          mb={{ base: '10px', lg: 0, xl: '10px' }}
        >
          We've received your agreement!
        </Text>
        <Text
          fontSize={{ base: '48px', lg: '64px', xl: '72px' }}
          lineHeight={{ base: '56px', lg: '72px', xl: '80px' }}
          fontWeight="extrabold"
          mb={{ base: '10px', lg: 0, xl: '10px' }}
        >
          Cool.
        </Text>
        <Text
          fontSize={{ base: '22px', lg: '28px', xl: '32px' }}
          lineHeight={{ base: '22px', lg: '28px', xl: '32px' }}
        >
          How can we <b>contact you?</b>
        </Text>
      </Box>
      {files.map((file) => (
        <Flex gap="6px">
          <Icon mt="8px" as={MdUploadFile} boxSize={6} />
          <Box>
            <Text fontSize="24px">{file.name}</Text>
            <Flex fontSize="16px" gap="10px">
              <Text color="royalBlue" cursor="pointer">
                Add More Files
              </Text>
              <Text color="redReplace" cursor="pointer">
                Replace
              </Text>
            </Flex>
          </Box>
        </Flex>
      ))}
      <Flex gap="10px" flexDir="column" my="30px">
        <Flex gap="10px">
          <Input h="48px" borderColor="transparent" placeholder="First name" />
          <Input h="48px" borderColor="transparent" placeholder="Last name" />
        </Flex>
        <Flex gap="10px">
          <Input h="48px" borderColor="transparent" placeholder="Email address" />
          <Input h="48px" borderColor="transparent" placeholder="Phone" />
        </Flex>
      </Flex>
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        textAlign={{ base: 'center', lg: 'left' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        mb={{ base: '40px', lg: '30px' }}
        alignItems="center"
      >
        <Button>Analyze My Lease!</Button>
        <Text>
          Protected by <b>Google reCAPTCHA</b>
        </Text>
      </Flex>
      <Divider />
      <Text mt="30px">
        Lease End's mission is to empower auto lease owners with the technology to easily exit their
        lease. If you'd like to learn more about the lease-end options available to you, please
        don't hesitate to contact us. Our expert advisors are always prepared to answer your
        questions and are committed to finding the right plan for your individual needs.
      </Text>
    </Box>
  );
};

export default LeasePeaceContactInfo;
