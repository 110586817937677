import { Box, BoxProps, Center, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';

import { CarPhoneLady } from '../../../../assets/Images';
import Odometer from '../Odometer';

export interface MidPageBannerProps extends BoxProps {
  data?: number | string;
}

const MobileMidPageBanner: React.FC<MidPageBannerProps> = ({ data, ...props }) => {
  return (
    <Box
      w="100vw"
      mt="60px"
      mb="20px"
      pb="20px"
      fontSize="28px"
      color="leaseEndBlue"
      fontWeight="light"
    >
      {data && (
        <Text textAlign="center" fontWeight={data ? 'normal' : 'semibold'} color="leaseEndBlue">
          We've helped
        </Text>
      )}
      <Center
        bgColor="headlightLadyBlue"
        w="100vw"
        h="150px"
        position="relative"
        pl="10%"
        pr="15%"
        mt="60px"
        mb="50px"
        {...props}
      >
        <Image src={CarPhoneLady} alt="Car Phone Lady" h="240px" position="absolute" top="-55px" />
      </Center>
      {!data && (
        <Text textAlign="center" fontWeight={data ? 'normal' : 'semibold'} color="leaseEndBlue">
          We've helped
        </Text>
      )}
      <Flex w="100%" justifyContent="center">
        {data ? (
          <Box
            color="driversYellow"
            fontSize="64px"
            lineHeight="64px"
            mt="-15px"
            mb="0px"
            fontWeight="bold"
          >
            {data}
          </Box>
        ) : (
          <>
            <Odometer ml="0" />
            <br />
          </>
        )}
        {data && (
          <Box
            ml={data ? '8px' : ''}
            h="72px"
            pt="8px"
            fontWeight={data ? 'normal' : 'semibold'}
            color="leaseEndBlue"
          >
            drivers
          </Box>
        )}
      </Flex>
      <Text
        textAlign="center"
        mt={data ? '-30px' : ''}
        fontWeight={data ? 'normal' : 'semibold'}
        color="leaseEndBlue"
      >
        {!data && 'drivers '}end their {data && 'vehicle '}leases.
      </Text>
    </Box>
  );
};

export default MobileMidPageBanner;
