import { Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';

import useHomePageVariations from '../../../hooks/useHomePageVariations';
import DesktopHelpCards from './DesktopComponents/DesktopHelpCards';
import MobileHelpCards from './MobileComponents/MobileHelpCards';

const HowCanWeHelp = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const homePageVariations = useHomePageVariations();

  return (
    <Flex
      flexDir="column"
      gap={{ base: '10px', md: '5px' }}
      p={{ base: '20px', md: '50px' }}
      bg="grayBackground"
      textAlign="center"
      mt="-1px"
    >
      <Heading fontWeight="semibold" fontSize={{ base: 25, md: 35 }} pb={{ base: 0, md: '20px' }}>
        How can we help?
      </Heading>
      {isMobile ? (
        <MobileHelpCards m="0" _accordionItem={{ color: 'black' }} />
      ) : (
        <DesktopHelpCards
          _subjectCardContainer={{ maxW: '1400px', m: '0 auto' }}
          _infoContainer={{ maxW: '1400px', m: '14px auto 0' }}
        />
      )}
      <Text mt={8} fontSize="16px">
        {homePageVariations.howCanWeHelp.description}
      </Text>
    </Flex>
  );
};

export default HowCanWeHelp;
