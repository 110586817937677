import { Flex, Heading } from '@chakra-ui/react';

import LicensePlateOrVinInput from '../components/LicensePlateOrVinInput';

const EndYourLease = () => {
  return (
    <Flex flexDir="column" gap="75px" p="50px 0 100px 0">
      <Heading
        textAlign="center"
        fontSize={{ base: '5xl', md: '5xl', lg: '6xl' }}
        fontWeight="thin"
        color="leaseEndBlue"
      >
        The Easiest Way to End Your Lease
      </Heading>
      <LicensePlateOrVinInput />
    </Flex>
  );
};

export default EndYourLease;
