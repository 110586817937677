import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

const StyledLabel = styled.label`
  text-transform: capitalize;
  text-align: left;
  display: grid;
  grid-template-columns: 1em auto;
  cursor: pointer;
  span {
    margin-left: 15px;
  }
`;

const StyledField = styled(Field).attrs({ type: 'radio' })`
  &&& {
    cursor: pointer;
    appearance: none;
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 0.15em solid ${({ theme }) => theme.colors.greenRadio};
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em ${({ theme }) => theme.colors.greenRadio};
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:hover {
      outline: max(5px, 0.5em) solid ${({ theme }) => theme.colors.greenRadioBGLightPressed};
    }
    &:focus {
      outline: 3px solid ${({ theme }) => theme.colors.greenRadioBGLightPressed};
    }
  }
`;

export type RadioProps = {
  name?: string;
  value?: string;
  className?: string;
  checked?: boolean;
  children: React.ReactNode;
};

const RadioButton: React.FC<RadioProps> = ({
  name,
  value,
  children,
  className,
  checked,
  ...props
}) => {
  return (
    <StyledLabel className={className} {...props}>
      <StyledField name={name} value={value} checked={checked} {...props} />
      <span>{children}</span>
    </StyledLabel>
  );
};

export default RadioButton;
