import { Box } from '@chakra-ui/react';

import Routes from './routes/Routes';

const App = () => {
  return (
    <Box w="w-screen" overflowX="hidden">
      <Routes />
    </Box>
  );
};

export default App;
