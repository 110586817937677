import { Box, BoxProps } from '@chakra-ui/react';
import { TouchEvent, useEffect, useState } from 'react';

interface Props extends BoxProps {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
}

const SwipeableBox = ({ children, onSwipeLeft, onSwipeRight, ...props }: Props) => {
  const [startX, setStartX] = useState<number>(0);
  const [endX, setEndX] = useState<number>(0);

  const handleSwipe = () => {
    const swipeThreshold = 60;
    const swipeDistance = endX - startX;

    // using the threshold to determine if the swipe was intentional
    if (swipeDistance > swipeThreshold) {
      onSwipeRight();
    } else if (swipeDistance < -swipeThreshold) {
      onSwipeLeft();
    }
  };

  useEffect(() => {
    handleSwipe();
  }, [endX]);

  return (
    <Box
      onTouchStart={(event: TouchEvent<HTMLDivElement>) =>
        setStartX(event.changedTouches[0].clientX)
      }
      onTouchEnd={(event: TouchEvent<HTMLDivElement>) => setEndX(event.changedTouches[0].clientX)}
      {...props}
    >
      {children}
    </Box>
  );
};

export default SwipeableBox;
