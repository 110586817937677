import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Description: React.FC<TextProps> = ({ children, ...props }) => (
  <Text
    fontSize={{ base: '14px', lg: '16px' }}
    fontWeight="normal"
    fontFamily="Monorale"
    color="midnightBlueDark"
    lineHeight={{ lg: '26px' }}
    {...props}
  >
    {children}
  </Text>
);

export default Description;
