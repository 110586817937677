import { Box, BoxProps, Container, Flex, HStack, IconProps, Text } from '@chakra-ui/react';
import { FaFacebookSquare } from '@react-icons/all-files/fa/FaFacebookSquare';
import { FaInstagramSquare } from '@react-icons/all-files/fa/FaInstagramSquare';
import { FaLinkedin } from '@react-icons/all-files/fa/FaLinkedin';
import { FaVimeoSquare } from '@react-icons/all-files/fa/FaVimeoSquare';
import { FaYoutubeSquare } from '@react-icons/all-files/fa/FaYoutubeSquare';
import { FaSquareXTwitter } from 'react-icons/fa6';

import {
  CAREERS,
  CONTACT_US,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  OPT_OUT,
  PRESS,
  PRIVACY_POLICY,
  TWITTER,
  VIMEO,
  YOUTUBE,
} from '../constants/urls';
import useSearchPhoneNumbers from '../hooks/useSearchPhoneNumbers';
import SocialMediaIconLink from './SocialMediaIconLink';
import { Link as ChakraLink, Link } from './ui/links/Link';

const currentYear = new Date().getFullYear();

interface FooterProps extends BoxProps {
  _socialMediaIcon?: IconProps;
}
const Footer = ({ _socialMediaIcon, ...props }: FooterProps) => {
  const phoneNumber = useSearchPhoneNumbers();

  return (
    <Box
      bgColor="grayBackground"
      mx="-50vw"
      right="50%"
      left="50%"
      position="relative"
      w="100vw"
      {...props}
    >
      <HStack pt={6} spacing={{ base: 5, md: 8, lg: 16 }} justifyContent="center">
        <SocialMediaIconLink
          icon={FaInstagramSquare}
          href={INSTAGRAM}
          ariaLabel="Lease End's Instagram Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaSquareXTwitter}
          href={TWITTER}
          ariaLabel="Lease End's Twitter Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaYoutubeSquare}
          href={YOUTUBE}
          ariaLabel="Lease End's Youtube Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaLinkedin}
          href={LINKEDIN}
          ariaLabel="Lease End's LinkedIn Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaFacebookSquare}
          href={FACEBOOK}
          ariaLabel="Lease End's Facebook Account"
          {..._socialMediaIcon}
        />
        <SocialMediaIconLink
          icon={FaVimeoSquare}
          href={VIMEO}
          ariaLabel="Lease End's Vimeo Account"
          {..._socialMediaIcon}
        />
      </HStack>
      <Container maxW="container.xl">
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          mb="50px"
          pt="20px"
        >
          <Link
            m="10px"
            fontSize={12}
            fontWeight="bold"
            to={CONTACT_US}
            aria-label="Contact Us"
            color={props.color}
            _hover={{ color: 'white' }}
          >
            CONTACT US
          </Link>
          <Link
            m="10px"
            fontSize={12}
            fontWeight="bold"
            to={CAREERS}
            isExternal
            aria-label="Careers with Lease End"
            color={props.color}
            _hover={{ color: 'white' }}
          >
            CAREERS
          </Link>
          <Link
            m="10px"
            fontSize={12}
            fontWeight="bold"
            to={PRESS}
            aria-label="Press Resources"
            color={props.color}
            _hover={{ color: 'white' }}
          >
            PRESS RESOURCES
          </Link>
          <Link
            m="10px"
            fontSize={12}
            fontWeight="bold"
            to={PRIVACY_POLICY}
            aria-label="Privacy Policy"
            color={props.color}
            _hover={{ color: 'white' }}
          >
            PRIVACY POLICY
          </Link>
          <Link
            m="10px"
            fontSize={12}
            fontWeight="bold"
            to={OPT_OUT}
            aria-label="Opt Out"
            color={props.color}
            _hover={{ color: 'white' }}
          >
            OPT OUT
          </Link>
        </Flex>
        <Flex pb="40px" mb="0px" flexDir={{ base: 'column', md: 'row' }}>
          <Box
            w={{ base: 'full', md: 3 / 5 }}
            color={props.color ?? 'leaseEndBlue'}
            fontSize="12px"
            fontWeight="semibold"
            mb={{ base: 4, md: 0 }}
            mr={{ base: 0, md: 4 }}
          >
            Lease End’s mission is to empower auto lease owners with the technology to easily exit
            their lease. If you’d like to learn more about the lease-end options available to you,
            please don’t hesitate to contact us. Our expert advisors are always prepared to answer
            your questions and are committed to finding the right plan for your individual needs.
            <br />
            <br />©{currentYear} Lease End
          </Box>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            paddingLeft={{ base: '0px !important', md: '30px !important' }}
            borderLeft={{ base: 'none', md: '2px solid' }}
            borderLeftColor="maizeCrayola !important"
            w={{ base: 'full', md: 2 / 5 }}
          >
            <Text fontWeight="semibold">Give Us a Call:</Text>
            <ChakraLink
              mt="5px"
              fontWeight="bold"
              fontSize="2rem"
              to={`tel:+1-${phoneNumber}`}
              aria-label="Call US"
              color={props.color ?? 'leaseEndBlue'}
              _hover={{ color: 'white' }}
            >
              {phoneNumber}
            </ChakraLink>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
