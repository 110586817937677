import { useToast } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

import { isProd } from '../config';
import { logout } from '../services/auth0';
import {
  LocalStorageKeys,
  getLocalStorageItemOrInitial,
  setLocalStorageItem,
} from '../services/localStorage';
import { CookieKeys, useCookie } from './useCookie';
import { useLocalStorage, useReadLocalStorage } from './useLocalStorage';

const ONE_SECOND = 1000;
const TIMEOUT_DELAY = ONE_SECOND;
const ONE_HOUR = 60 * 60 * ONE_SECOND;

const useInactivityLogout = () => {
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const devExpiration = useReadLocalStorage<string>(LocalStorageKeys.DEV_TEST_EXPIRATION);
  const [inactivityLogout, setInactivityLogout] = useLocalStorage<boolean>(
    LocalStorageKeys.INACTIVITY_LOGOUT,
    false,
  );

  const logoutInitiated = useRef<boolean>(false);
  const toast = useToast();

  // 1 hour or token expiration in seconds if testing
  const timeoutPeriod = devExpiration && !isProd ? Number(devExpiration) * ONE_SECOND : ONE_HOUR;

  // post logout toast
  useEffect(() => {
    // Show toast if user has been logged out due to inactivity.
    let timeout: ReturnType<typeof setTimeout>;
    if (inactivityLogout) {
      timeout = setTimeout(() => {
        toast({
          title: 'You have been logged out due to inactivity.',
          status: 'warning',
          duration: null,
          isClosable: true,
          position: 'top-right',
        });
      }, 100);
      // Reset logged out in local storage.
      setInactivityLogout(false);
    }
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    // Check if 1 hour has passed since last reset,
    // and, if so, log the user out.
    let logoutTimeout: ReturnType<typeof setTimeout>;
    let checkTimeout: ReturnType<typeof setTimeout>;
    const checkTimer = () => {
      if (!accessToken) return;

      const lastActivity = getLocalStorageItemOrInitial(LocalStorageKeys.LAST_ACTIVITY, Date.now());
      const timeSinceLastActivity = Date.now() - lastActivity;
      // If testing number in local storage, log checks.
      if (devExpiration && !isProd) {
        // eslint-disable-next-line no-console
        console.log(`${Math.floor(timeSinceLastActivity / ONE_SECOND)}s / ${devExpiration}s`);
      }

      // log out if 1 hour has passed since last activity (or token expiration in seconds if testing)
      if (timeSinceLastActivity >= timeoutPeriod) {
        if (!logoutInitiated.current) {
          toast({
            title: 'Logging out due to inactivity...',
            status: 'warning',
            duration: ONE_SECOND,
            isClosable: true,
            position: 'top-right',
          });
          logoutTimeout = setTimeout(async () => {
            logout();
          }, ONE_SECOND); // Wait for toast to show before logging out.
          logoutInitiated.current = true; // This prevents the toast from showing more than once.
          setInactivityLogout(true); // This shows a toast when the page refreshes after logout.
        }
      } else {
        // reset timer to check again when it will expire
        checkTimeout = setTimeout(
          checkTimer,
          timeoutPeriod - timeSinceLastActivity + TIMEOUT_DELAY,
        );
      }
    };

    // Check timer every second if testing, otherwise every minute.
    checkTimeout = setTimeout(checkTimer, timeoutPeriod + TIMEOUT_DELAY);
    return () => {
      clearInterval(checkTimeout);
      clearTimeout(logoutTimeout);
    };
  }, [accessToken, timeoutPeriod, logoutInitiated]);

  useEffect(() => {
    const setLastActivity = () => {
      setLocalStorageItem(LocalStorageKeys.LAST_ACTIVITY, Date.now());
    };

    // Add event listeners for common user actions.
    if (accessToken) {
      setLastActivity();
      window.addEventListener('mousemove', setLastActivity);
      window.addEventListener('mousedown', setLastActivity);
      window.addEventListener('keypress', setLastActivity);
      window.addEventListener('touchmove', setLastActivity);
      window.addEventListener('scroll', setLastActivity);
      window.addEventListener('wheel', setLastActivity);
    }

    return () => {
      window.removeEventListener('mousemove', setLastActivity);
      window.removeEventListener('mousedown', setLastActivity);
      window.removeEventListener('keypress', setLastActivity);
      window.removeEventListener('touchmove', setLastActivity);
      window.removeEventListener('scroll', setLastActivity);
      window.removeEventListener('wheel', setLastActivity);
    };
  }, [accessToken]);
};

export default useInactivityLogout;
