import React from 'react';
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';

interface Props extends LinkProps {
  to: string;
  children: React.ReactNode;
}

export const Link: React.FC<Props> = ({ to, children, ...rest }) => {
  return (
    <ChakraLink
      href={to}
      fontFamily="Monorale"
      fontWeight="medium"
      color="leaseEndBlue"
      fontSize="14px"
      letterSpacing="1.4px"
      textDecoration="none !important"
      {...rest}
    >
      {children}
    </ChakraLink>
  );
};
