import { Box, Divider, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { MdChevronRight } from 'react-icons/md';

import {
  AntiBot,
  Logo,
  MagnifyingGlass,
  OdometerPointer,
  ResultsCheck,
} from '../../../assets/Images';

const LeasePeaceHow = () => {
  return (
    <Flex
      m="0 auto"
      w="100%"
      mb="85px"
      gap={{ lg: '4%', xl: '5%' }}
      p={{ base: '0 10%', lg: '0 4%', xl: '0 7%' }}
      textAlign={{ base: 'center', lg: 'left' }}
      justifyContent="center"
    >
      <Flex
        flexDir="column"
        fontSize="18px"
        gap="45px"
        w={{ base: '100%', lg: '49%', xl: '570px' }}
      >
        <Text fontSize="42px">How it works</Text>
        <Flex gap="45px" alignItems="center">
          <Flex gap={{ base: '20px', lg: 'unset' }} flexDir="column">
            <Text color="royalBlue" fontSize="28px">
              Upload lease agreement
            </Text>
            <Image
              src={MagnifyingGlass}
              w="125px"
              display={{ base: 'block', lg: 'none' }}
              m="0 auto"
            />
            <Text w={{ base: '100%', lg: '100%', xl: '400px' }}>
              We just need a page or two from your <b>current lease agreement</b> so we know what
              we're working with
            </Text>
          </Flex>
          <Image src={MagnifyingGlass} w="125px" display={{ base: 'none', lg: 'block' }} />
        </Flex>
        <Flex gap="45px" alignItems="center">
          <Image src={AntiBot} w="125px" h="125px" display={{ base: 'none', lg: 'block' }} />
          <Flex gap={{ base: '20px', lg: 'unset' }} flexDir="column">
            <Text color="royalBlue" fontSize="28px">
              A.I.? Nah...we like humans
            </Text>
            <Image
              src={AntiBot}
              w="125px"
              h="125px"
              display={{ base: 'block', lg: 'none' }}
              m="0 auto"
            />
            <Text w={{ base: '100%', lg: '100%', xl: '400px' }}>
              A dedicated lease expert will swiftly <b>analyze your agreement</b> and generate your
              end of lease options
            </Text>
          </Flex>
        </Flex>
        <Flex gap="45px" alignItems="center">
          <Flex gap={{ base: '20px', lg: 'unset' }} flexDir="column">
            <Text color="royalBlue" fontSize="28px">
              We send you the results
            </Text>
            <Image
              src={ResultsCheck}
              w="125px"
              display={{ base: 'block', lg: 'none' }}
              m="0 auto"
            />
            <Text w={{ base: '100%', lg: '100%', xl: '400px' }}>
              We'll send <b>current equity</b>, <b>mileage protection options</b> and an{' '}
              <b>estimated monthly payment</b>
            </Text>
          </Flex>
          <Image src={ResultsCheck} w="125px" display={{ base: 'none', lg: 'block' }} />
        </Flex>
      </Flex>
      <Flex
        flexDir="column"
        gap="50px"
        w={{ lg: '51%', xl: '570px' }}
        display={{ base: 'none', lg: 'flex' }}
      >
        <Text fontSize="42px">Why Lease End?</Text>
        <Box w={{ base: '100%', xl: '570px' }}>
          <Text fontSize="16px" w="100%">
            I was really skeptical...but let me tell you this company is amazing! They explained
            everything to me and made the process so easy! They got me a great deal with an extended
            warranty with an affordable payment! I encourage everyone to give Lease End an
            opportunity to serve your personal needs! Excellent company!
          </Text>
          <Flex alignItems="center" justifyContent="right" gap="10px">
            <Text fontSize="18px">Marie R.</Text>
            <Text
              fontSize="20px"
              bgColor="evergreen"
              color="white"
              ml="5px"
              p="5px"
              textAlign="center"
              borderRadius="50%"
            >
              MR
            </Text>
          </Flex>
          <Flex
            justifyContent="right"
            alignItems="center"
            fontSize="16px"
            mt="10px"
            color="royalBlue"
          >
            <Text>Read more</Text>
            <Icon as={MdChevronRight} />
          </Flex>
        </Box>
        <Flex gap="20px" alignItems="center">
          <Image src={OdometerPointer} h="50px" />
          <Text fontSize="16px" fontStyle="italic" fontWeight="semibold">
            Driver's who've ended their lease with Lease End... and counting!
          </Text>
        </Flex>
        <Divider />
        <Flex gap="30px" alignItems="flex-start">
          <Image src={Logo} h="40px" />
          <Text fontSize="18px" fontStyle="italic">
            Lease End's mission is to empower auto lease owners with technology to easily exit their
            lease.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LeasePeaceHow;
