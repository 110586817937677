import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

interface SubjectCardProps {
  handleHover: () => void;
  children: React.ReactNode;
  isActive?: boolean;
}

const SubjectCard: React.FC<SubjectCardProps> = ({ handleHover, children, isActive }) => {
  return (
    <Flex
      onMouseOver={handleHover}
      h="225px"
      w="23%"
      boxShadow="md"
      borderRadius="10px"
      justifyContent="center"
      flexDir="column"
      p={8}
      bgColor={isActive ? 'activeBlue' : 'white'}
      cursor="pointer"
      position="relative"
    >
      {children}
      {isActive && (
        <Box
          position="absolute"
          left="50%"
          transform="translateX(-15px)"
          top="224px"
          borderTop="30px solid"
          borderTopColor="activeBlue"
          borderLeft="20px solid transparent"
          borderRight="20px solid transparent"
          w="30px"
        />
      )}
    </Flex>
  );
};

export default SubjectCard;
