/* eslint-disable react/destructuring-assignment */
import { SanityImageSource, getImageDimensions } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';
import { useEffect, useState } from 'react';

import client from '../../../client';

interface BlogImageComponentProps {
  value: SanityImageSource & { alt?: string; width?: number };
  isInline: boolean;
  flavor?: string;
}

export const BlogImageComponent = (props: BlogImageComponentProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const original: {
    width: number;
    height: number;
  } = getImageDimensions(props.value);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const size = () => {
    setIsPortrait(original.height > original.width);
    const max_dimension: number = props.isInline
      ? props.flavor && props.flavor === 'snippet'
        ? 300
        : 44
      : isPortrait
      ? original.height
      : original.width;
    if (isPortrait) {
      setHeight(max_dimension);
      setWidth(max_dimension * (original.width / original.height));
    } else {
      setWidth(max_dimension);
      setHeight(max_dimension * (original.height / original.width));
    }
  };
  useEffect(() => {
    if (props.value) {
      size();
    }
  }, [props]);
  return (
    <img
      src={urlBuilder(client)
        // eslint-disable-next-line react/destructuring-assignment
        .image(props.value)
        // eslint-disable-next-line react/destructuring-assignment
        .width(Math.round(width))
        .height(Math.round(height))
        .url()}
      alt={props.value.alt || 'Lease End'}
      loading="lazy"
      style={{
        display: props.isInline ? 'inline-block' : 'block',
        aspectRatio: width / height,
        borderRadius: 8,
        padding: props.flavor && props.flavor === 'snippet' ? 4 : 0,
        border: props.flavor && props.flavor === 'snippet' ? '1px solid #aaa' : '',
      }}
    />
  );
};

export default BlogImageComponent;
