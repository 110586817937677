import { SanityImageSource, getImageDimensions } from '@sanity/asset-utils';
import urlBuilder from '@sanity/image-url';

import client from '../../../client';

export const AuthorImageComponent = (props: { value: SanityImageSource; isInline: boolean }) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { width, height } = getImageDimensions(props.value);
  return (
    <img
      src={urlBuilder(client)
        // eslint-disable-next-line react/destructuring-assignment
        .image(props.value)
        // eslint-disable-next-line react/destructuring-assignment
        .width(props.isInline ? 32 : 96)
        .fit('max')
        .auto('format')
        .url()}
      alt=""
      loading="lazy"
      style={{
        // eslint-disable-next-line react/destructuring-assignment
        display: props.isInline ? 'inline-block' : 'block',
        aspectRatio: width / height,
      }}
    />
  );
};

export default AuthorImageComponent;
