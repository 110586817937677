import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleInterpolation } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle: PartsStyleInterpolation = definePartsStyle({
  overlay: {
    bg: 'blackAlpha.800',
  },
  dialog: {
    borderRadius: '15px',
    my: '100px',
  },
  header: {
    px: '30px',
    pt: '30px',
    pb: '0',
    fontWeight: 'normal',
    color: 'leaseEndBlue',
  },
  body: {
    pt: '1',
    pb: '30px',
    py: '30px',
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
