/* eslint-disable react/destructuring-assignment */

/* eslint-disable security/detect-non-literal-fs-filename */
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  BeMyCarHeart,
  DriveMeWildHeart,
  July4100FW1,
  July4100Jump,
  ValentineGuy,
  WinHeart,
} from '../../../assets/Images';
import { FLOW_ENTRY } from '../../../constants/urls';
import { RudderEvent, rudderanalytics } from '../../../utils/rudderstack';
import HomeBannerModal from './HomeBannerModal';

const HomePageBannerAd = (props: { flavor?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const goStart = () => {
    let appendParams = '';
    if (urlParams) {
      urlParams.forEach((value: string, key: string) => {
        appendParams += `&${key}=${value}`;
      });
    }
    rudderanalytics.track(RudderEvent.Promo, {
      name: 'Promo-July2024',
      event: 'click',
      media: 'HomePageBanner',
    });
    window.open(`${FLOW_ENTRY}?promo=Promo-July2024${appendParams}`, '_self');
  };

  return (
    <>
      {props.flavor && props.flavor === 'Promo-July2024' ? (
        <>
          <Flex
            dir="column"
            justifyContent="center"
            mt={{ base: '-71px', sm: '-100px', md: '-71px' }}
            mb="24px"
            bgColor="leaseEndBlue"
            color="white"
            w="100%"
            p={5}
            pb={10}
            pt={{ base: 0, sm: 0, md: 10 }}
            minH="100px"
            borderTopWidth={6}
            borderTopColor="#cc1d3a"
            position="relative"
            borderBottomColor="#cc1d3a"
            borderBottomWidth={6}
          >
            <Flex borderTopWidth={3} borderTopColor="#FFF" w="100%" position="absolute" top={0}>
              &nbsp;
            </Flex>
            <Flex
              borderTopWidth={3}
              borderTopColor="#FFF"
              w="100%"
              position="absolute"
              bottom="-21px"
            >
              &nbsp;
            </Flex>
            <Box position="relative" maxW={{ base: '700px', sm: '500px', md: '800px' }}>
              <Image
                src={July4100Jump}
                position="absolute"
                top={{ base: '-165px', sm: '50px', md: '0px', lg: '-30px' }}
                left={{ base: '120px', sm: '-30px', md: '-110px', lg: '-155px' }}
                w={{ base: '120px', lg: '160px' }}
                filter="drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))"
                zIndex={95}
              />
              <Image
                src={July4100FW1}
                position="absolute"
                top={{ base: '-165px', sm: '20px', md: '-10px', lg: '-30px' }}
                left={{ base: '-30px', sm: '-100px', md: '-160px', lg: '-190px' }}
                w={{ base: '200px', lg: '240px' }}
                filter="drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))"
                zIndex={90}
                opacity={0.75}
              />
              <Text
                pt={{ base: 8, md: 0 }}
                pb={{ base: 2, md: 0 }}
                ps={{ base: 20, sm: 32 }}
                mb={4}
                textAlign="left"
                fontSize={{ base: '32px', sm: '32px', md: '40px', lg: '42px' }}
                fontWeight="bold"
                lineHeight={1}
                zIndex={99}
                position="relative"
              >
                Independence from Your Lease!
              </Text>
              <Text
                textAlign="left"
                fontSize={{ base: '16px', md: '22px' }}
                pe={{ base: 10, md: 0 }}
                pb={2}
                ps={{ base: 20, sm: 32 }}
              >
                End your lease with us from July 1st through July 6th and we'll give you a $100 gift
                card.
              </Text>
              <Flex
                textAlign="left"
                pe={{ base: 10, md: 0 }}
                pb={2}
                ps={{ base: 20, sm: 32 }}
                mt={4}
                flexDirection={{ base: 'row', sm: 'column', md: 'row' }}
                justifyContent="flex-start"
              >
                <Button
                  onClick={() => goStart()}
                  type="submit"
                  py="20px"
                  fontWeight="600"
                  isLoading={false}
                  width={{ base: 'auto', sm: '100%', md: 'auto' }}
                >
                  GET STARTED
                </Button>
                <Button
                  mt={{ base: 0, sm: 4, md: 0 }}
                  ms={4}
                  onClick={() => setIsOpen(true)}
                  variant="link"
                  fontSize={{ base: '12px', md: '14px' }}
                  color="white"
                  textDecoration="underline"
                  _hover={{ color: 'white' }}
                >
                  Terms & Conditions
                </Button>
              </Flex>
            </Box>
          </Flex>
          <HomeBannerModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
      ) : (
        <>
          <Flex
            dir="column"
            justifyContent="center"
            mt="-45px"
            bgColor="cardinalRed"
            color="white"
            w="100%"
            p={5}
            minH="100px"
          >
            <Box position="relative" maxW="700px">
              <Image
                src={WinHeart}
                position="absolute"
                top={{ base: '-65px', md: '', lg: '-65px' }}
                left={{ base: '-30px', md: '-130px', xl: '-160px' }}
                w={{ base: '120px', lg: '140px' }}
                filter="drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))"
              />
              <Image
                src={DriveMeWildHeart}
                position="absolute"
                top={{ base: '-65px', md: '', lg: '-35px' }}
                right={{ base: '-30px', md: '-165px', xl: '-265px' }}
                w={{ base: '120px', lg: '140px' }}
                filter="drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))"
              />
              <Image
                src={BeMyCarHeart}
                position="absolute"
                top={{ base: '-115px', md: '', lg: '-65px' }}
                right={{ base: '50px', md: '-100px', xl: '-160px' }}
                w={{ base: '120px', lg: '140px' }}
                filter="drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.5))"
              />
              <Text
                pt={{ base: 8, md: 0 }}
                pb={{ base: 4, md: 0 }}
                mb={4}
                textAlign="center"
                fontSize={{ base: '38px', md: '48px' }}
                fontWeight="bold"
              >
                We're giving <Text as="br" display={{ base: 'block', lg: 'none' }} />5 people $500!
              </Text>
              <Text
                textAlign="center"
                fontSize={{ base: '16px', md: '18px' }}
                px={{ base: 10, md: 0 }}
                pb={2}
              >
                End your lease with us by February 29th and record a video telling us why you loved
                your Lease End experience for a chance to win!{' '}
                <Button
                  onClick={() => setIsOpen(true)}
                  variant="link"
                  fontSize={{ base: '12px', md: '14px' }}
                  color="white"
                  textDecoration="underline"
                  _hover={{ color: 'white' }}
                >
                  Terms & Conditions
                </Button>
              </Text>
            </Box>
            <Image
              src={ValentineGuy}
              display={{ base: 'none', md: 'block' }}
              position="absolute"
              top="200px"
              left={{ base: '65px', lg: '150px' }}
              w={{ base: '160px', lg: '200px' }}
            />
          </Flex>
          <HomeBannerModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
      )}
    </>
  );
};

export default HomePageBannerAd;
